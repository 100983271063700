import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { inputPhoneNumberLogin, inputPasswordLogin } from "../libs/validations"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { InputText, Loading } from "./form_elements/index"

const theme = createTheme()

const LoginForm = ({
  open,
  isLoading,
  handleClose,
  handleSubmit,
  phoneNumber,
  password,
  setPhoneNumber,
  setPassword,
  errors,
  setErrors,
}) => {
  return (
    // props received from App.js
    <Dialog open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h6">
              電話番号とパスワードでログイン
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <InputText
                fullWidth={true}
                label={"電話番号"}
                multiline={false}
                required={true}
                onChange={inputPhoneNumberLogin}
                rows={1}
                inputName="phoneNumberLogin"
                value={phoneNumber}
                type={"tel"}
                helperText="例：09012345678"
                setValue={setPhoneNumber}
                errors={errors}
                setErrors={setErrors}
              />
              <InputText
                fullWidth={true}
                label={"パスワード"}
                multiline={false}
                required={true}
                onChange={inputPasswordLogin}
                rows={1}
                inputName="passwordLogin"
                value={password}
                type={"password"}
                setValue={setPassword}
                errors={errors}
                setErrors={setErrors}
              />

              {errors.notFound && (
                <div className="text-red-500 font-bold text-sm text-center my-2">
                  ログイン情報を正しく入力してください。
                </div>
              )}
              {isLoading === false ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={
                    phoneNumber === "" ||
                    password === "" ||
                    errors.phoneNumberLogin !== "" ||
                    errors.passwordLogin !== ""
                      ? true
                      : false
                  }
                >
                  ログイン
                </Button>
              ) : (
                <Loading />
              )}
              <Grid container>
                <Grid item xs>
                  <a
                    className="text-xs"
                    href={`mailto:${process.env.PASSWD_MAIL}?subject=パスワードの再取得&body=そのまま送信してください。`}
                  >
                    ※パスワードを忘れた方
                  </a>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    キャンセル
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Dialog>
  )
}

export default LoginForm
