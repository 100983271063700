import React from "react"

const Sc = () => {
  return (
    <>
      <script
        src="https://www.googletagmanager.com/gtag/js?id=AW-11159069842"
        type="text/javascript"
        async
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-11159069842');
        `,
        }}
      />
    </>
  )
}

export default Sc
