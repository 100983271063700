import React from "react"
import Divider from "@mui/material/Divider"

const DividerTitle = props => {
  const { title } = props
  return (
    <Divider
      sx={{
        p: 2,
        mt: 3,
        mb: 1,
      }}
      textAlign="center"
      className="text-2xl font-bold bg-indigo-50 rounded"
    >
      {title}
    </Divider>
  )
}

export default DividerTitle
