import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import CartSm from "./CartSm"

const MainSm = props => {
  const { inputGoodsId, Scroll, company } = props
  const data = useStaticQuery(graphql`{
    recommend_bg: file(relativePath: {eq: "sm/cv_bg.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 960, layout: CONSTRAINED)
      }
    }
  }
  `)
  return (
    <div className="mx-auto my-0 text-center max-w-5xl">
      <div className="img_box">
        <StaticImage
          width={960}
          height={159}
          src={"../assets/images/sm/f_A01.png"}
          alt={"全ての女性を虜にしてしまう最強のフェロモン香水のFforMEN"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1211}
          src={"../assets/images/sm/f_A02.png"}
          alt={"どんなオトコも香りでモテろ！"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={461}
          src={"../assets/images/sm/f_A03.png"}
          alt={"ご注意ください"}
          placeholder="blurred"
          quality="65"
        />
      </div>

      <CartSm inputGoodsId={inputGoodsId} Scroll={Scroll} company={company} />

      <div className="img_box">
        <StaticImage
          width={960}
          height={183}
          src={"../assets/images/sm/f_A11.png"}
          alt={"F for MENの匂いを嗅ぐ毎に"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={721}
          src={"../assets/images/sm/f_A12.png"}
          alt={"恋心を呼び起こす"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={939}
          src={"../assets/images/sm/f_A13.png"}
          alt={"女性に香りで恋をする脳波を直接刺激するから"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={451}
          src={"../assets/images/sm/f_A14.png"}
          alt={"清楚なナース、強気な上司、貞操の硬い人妻、可愛い後輩"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={816}
          src={"../assets/images/sm/f_A15.png"}
          alt={"止まらない感情に激しく溺れてしまう"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1145}
          src={"../assets/images/sm/f_B01.png"}
          alt={"その日のうちに落とした確率なんと95%以上"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={714}
          src={"../assets/images/sm/f_B02.png"}
          alt={"多くの女性が匂いに惹かれていました"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={798}
          src={"../assets/images/sm/f_B03.png"}
          alt={"不細工な俺が・・・"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={724}
          src={"../assets/images/sm/f_B04.png"}
          alt={"NO1になりました！"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={682}
          src={"../assets/images/sm/f_B05.png"}
          alt={"キャバ嬢ゲットしました"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={691}
          src={"../assets/images/sm/f_B06.png"}
          alt={"覚醒しました"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={815}
          src={"../assets/images/sm/f_B07.png"}
          alt={"40歳を過ぎてモテ期到来"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={639}
          src={"../assets/images/sm/f_B08.png"}
          alt={"女性にモテモテ"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={659}
          src={"../assets/images/sm/f_C01.png"}
          alt={"ここまで女を狂わせるその秘密とは..."}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1139}
          src={"../assets/images/sm/f_C02.png"}
          alt={"香りで女性が恋をするメカニズム"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1042}
          src={"../assets/images/sm/f_C03.png"}
          alt={"では、女性を魅了する匂いとは？"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1144}
          src={"../assets/images/sm/f_C04.png"}
          alt={"ずばり、フェロモン"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1237}
          src={"../assets/images/sm/f_C05.png"}
          alt={"フェロモンには出やすい時期体質がある"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={792}
          src={"../assets/images/sm/f_C06.png"}
          alt={"テストステロンと同様の匂い"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1785}
          src={"../assets/images/sm/f_D00.jpg"}
          alt={"オスモフェロモン"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={580}
          src={"../assets/images/sm/f_D01.png"}
          alt={"女脳を虜に"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={441}
          src={"../assets/images/sm/f_D02.png"}
          alt={"F for MENはこの一本に"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={551}
          src={"../assets/images/sm/f_D03.png"}
          alt={"女性が求める理想の男性像を演出"}
          placeholder="blurred"
          quality="65"
        />
      </div>

      <CartSm inputGoodsId={inputGoodsId} Scroll={Scroll} company={company} />

      <div className="img_box">
        <StaticImage
          width={960}
          height={1253}
          src={"../assets/images/sm/f_D04.png"}
          alt={"F for menをつけるタイミング"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1074}
          src={"../assets/images/sm/f_D05.png"}
          alt={"F for menは直接地肌に付ける"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={1135}
          src={"../assets/images/sm/f_D06.png"}
          alt={"F for menを付ける場所"}
          placeholder="blurred"
          quality="65"
        />
      </div>
      <a href={`tel:${company.phone}`}>
        <StaticImage
          width={960}
          height={359}
          src={"../assets/images/sm/f_D07.png"}
          alt={"お電話でのご注文はコチラ"}
          placeholder="blurred"
          quality="65"
        />
      </a>
      <style jsx="true" global="true">
        {`
          .img_box > div {
            overflow: hidden;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            width: 100%;
            margin: 0 auto -1px;
            padding: 0;
          }
          .img_box > div > img {
            width: 100%;
            max-width: 100%;
          }
          .recommend {
            margin: 0;
            padding:0;
            background: url(${data.recommend_bg.publicURL});
            max-width:960px;
            min-width: 320px;
            background-repeat: repeat-y;
            background-size: cover;
          }
        `}
      </style>
    </div>
  )
}

export default MainSm
