import React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import FormHelperText from "@mui/material/FormHelperText"

const SelectBox = props => {
  const label = props.required === true ? props.label + " *" : props.label
  const error = props.error ? true : false

  return (
    <FormControl
      required={props.required}
      error={error}
      fullWidth={props.fullWidth}
      component="fieldset"
      className="mt-2"
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        required={props.required}
        value={props.value}
        label={label}
        onChange={event => props.select(event.target.value)}
      >
        {props.options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label.replace("null", "")}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  )
}

export default SelectBox
