import React from "react"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"

const TextInput = props => {
  const inputName = props.inputName
  const validate = event => {
    props.onChange(
      event.target.value,
      props.setValue,
      props.errors,
      props.setErrors,
      props.options
    )
  }

  const error = props.errors[inputName] ? true : false
  const mLength = props.maxLength > 0 ? props.maxLength : 100

  return (
    <FormControl
      required={props.required}
      error={error}
      fullWidth={props.fullWidth}
      component="fieldset"
    >
      <TextField
        fullWidth={props.fullWidth}
        label={props.label}
        margin="dense"
        error={error}
        autoComplete="off"
        multiline={props.multiline}
        required={props.required}
        rows={props.rows}
        value={props.value}
        type={props.type}
        inputProps={{ maxLength: mLength }}
        helperText={props.helperText}
        onChange={event => validate(event)}
      />
      <FormHelperText className="text-red-500">
        {props.errors[inputName]}
      </FormHelperText>
    </FormControl>
  )
}
export default TextInput
