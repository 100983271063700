import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import MobileDatePicker from "@mui/lab/MobileDatePicker"
import moment from "moment"
import { Config } from "../../site.config"
import {
  Label,
  InputText,
  SelectBox,
  SelectBirthday,
  BoxSelect,
  Payment,
  DividerTitle,
  DividerHr,
  Loading,
} from "./form_elements/index"
import { fetchData, fetchDataPost } from "../libs/fetchers"
import * as Validations from "../libs/validations"
import Login from "./Login"
import Address from "./Address"
import Inquiry from "./Inquiry"
import CartButton from "../assets/images/btn_confirm.gif"

const Form = props => {
  const {
    companyId,
    sessionId,
    arg,
    val,
    goodsId,
    goodsCount,
    setGoodsId,
    setGoodsCount,
    inputGoodsId,
    inputGoodsCount,
    goodsList,
  } = props

  // for deliverClientDate
  const deliverStartDate = moment()
    .add(Config.deliverStartCount, "days")
    .format("YYYY-MM-DD")
  const deliverEndDate = moment()
    .add(Config.deliverEndCount, "days")
    .format("YYYY-MM-DD")

  // ----------------------------------------------------
  // useState
  // ----------------------------------------------------
  const [isLogin, setIsLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const [isOk, setIsOk] = useState("")

  const [cartConfig, setCartConfig] = useState("")
  const [errorsLogin, setErrorsLogin] = useState({
    phoneNumberLogin: "",
    passwordLogin: "",
  })
  const [prefectureList, setPrefectureList] = useState([])
  const [paymentList, setPaymentList] = useState([])
  const [deliverList, setDeliverList] = useState([])
  const [holidayList, setHolidayList] = useState([])
  const [clientAddressList, setClientAddressList] = useState([])

  const [clientId, setClientId] = useState(0)
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [mailAddress, setMailAddress] = useState("")
  const [birthday, setBirthday] = useState("")
  const [birthYear, setBirthYear] = useState("")
  const [birthMonth, setBirthMonth] = useState("")
  const [birthDay, setBirthDay] = useState("")
  const [gender, setGender] = useState(1)
  const [zipCode, setZipCode] = useState("")
  const [prefectureId, setPrefectureId] = useState("")
  const [address, setAddress] = useState("")
  const [addressDetail, setAddressDetail] = useState("")
  const [clientAddressId, setClientAddressId] = useState(0)
  const [deliver, setDeliver] = useState(0)
  const [deliverClientName, setDeliverClientName] = useState("")
  const [deliverZipCode, setDeliverZipCode] = useState("")
  const [deliverPrefectureId, setDeliverPrefectureId] = useState("")
  const [deliverAddress, setDeliverAddress] = useState("")
  const [deliverAddressDetail, setDeliverAddressDetail] = useState("")
  const [deliverPhoneNumber, setDeliverPhoneNumber] = useState("")
  const [paymentId, setPaymentId] = useState("")
  const [deliverId, setDeliverId] = useState(7)
  const [deliverClientDate, setDeliverClientDate] = useState("")
  const [password, setPassword] = useState("")
  const [sendMailFlg, setSendMailFlg] = useState(1)
  const [comment, setComment] = useState("")

  // credit
  const [cardNo, setCardNo] = useState("")
  const [cardName, setCardName] = useState("")
  const [expireDate, setExpireDate] = useState("")
  const [securityCode, setSecurityCode] = useState("")
  const [creditToken, setCreditToken] = useState("")

  // login form
  const [phoneNumberLogin, setPhoneNumberLogin] = useState("")
  const [passwordLogin, setPasswordLogin] = useState("")

  // modal
  const [open, setOpen] = useState(false)
  const [openAddress, setOpenAddress] = useState(false)
  const [openInquiry, setOpenInquiry] = useState(false)

  // for errors
  const [errors, setErrors] = useState({
    goodsId: "",
    goodsCount: "",
    birthday: "",
    gender: "",
    addressDetail: "",
    clientAddressId: "",
    deliverClientName: "",
    deliverZipCode: "",
    deliverPrefectureId: "",
    deliverAddress: "",
    deliverAddressDetail: "",
    deliverPhoneNumber: "",
    deliverClientDate: "",
    password: "",
    sendMailFlg: "",
    comment: "",
    cardNo: "",
    cardName: "",
    expireDate: "",
    securityCode: "",
    creditToken: "",
  })
  // deliverClientDate
  const inputDeliverClientDate = value => {
    if (value) {
      value = moment(value).format("YYYY-MM-DD")
    }

    let errorMessage = ""
    if (value) {
      if (!value.match(/^\d{4}-\d{2}-\d{2}$/)) {
        errorMessage = "正しい配送希望日を入力してください"
      }
      setErrors({ ...errors, deliverClientDate: errorMessage })
      setDeliverClientDate(value)
    } else {
      setErrors({ ...errors, deliverClientDate: "" })
      setDeliverClientDate("")
    }
  }

  // ----------------------------------------------------
  // useEffect
  // ----------------------------------------------------
  useEffect(() => {
    const sess = sessionStorage.getItem("session_id")
    getCartConfig(Config.companyId)
    getPrefectureList(Config.companyId)
    getPaymentList(Config.companyId)
    getDeliverList(Config.companyId)
    getHolidayList()
    regetLogin(sess)
  }, [])

  // ----------------------------------------------------
  // fetchers
  // ----------------------------------------------------
  const getCartConfig = async company_id => {
    const data = await fetchData("cart_config/getData.php", {
      company_id: company_id,
    })
    setCartConfig(data)
  }

  const getPrefectureList = async company_id => {
    const data = await fetchData("prefectures/getList.php", {
      company_id: company_id,
    })
    const newData = data.map(e => ({
      value: e["prefecture_id"],
      label: e["prefecture_name"],
    }))
    setPrefectureList(newData)
  }

  const getPaymentList = async company_id => {
    const data = await fetchData("payments/getList.php", {
      company_id: company_id,
    })
    let newData = []
    data.forEach(function (dat) {
      if (Config.amazonFlg === false && dat.payment_id === "7") {
        return
      }
      if (dat.payment_id === "4") {
        dat["payment_name"] = '後払い（コンビニ・郵便局・銀行・LINE Pay）'
      }
      newData.push({
        value: dat["payment_id"],
        label: dat["payment_name"],
      })
    })
    setPaymentList(newData)
  }

  const getDeliverList = async company_id => {
    const data = await fetchData("delivers/getList.php", {
      company_id: company_id,
    })
    const newData = data.map(e => ({
      value: e["deliver_id"],
      label: e["deliver_name"],
    }))
    setDeliverList(newData)
  }

  const getHolidayList = async () => {
    const data = await fetchData("holidays/getList.php")
    let newData = Array()
    if (data !== null && data.length > 0) {
      newData = data.map(e => e["holiday_date"])
    }
    setHolidayList(newData)
  }

  // ----------------------------------------------------
  // functions
  // ----------------------------------------------------
  // modal
  const handleOpen = () => {
    setOpen(true)
  }
  // function to handle modal close
  const handleClose = () => {
    setErrorsLogin({
      phoneNumberLogin: "",
      passwordLogin: "",
      notFound: "",
    })
    setOpen(false)
  }
  // modal address
  const handleOpenAddress = () => {
    setOpenAddress(true)
  }
  // function to handle modal address close
  const handleCloseAddress = () => {
    setOpenAddress(false)
  }
  // modal inquiry
  const handleOpenInquiry = () => {
    setOpenInquiry(true)
  }
  // function to handle modal inquiry close
  const handleCloseInquiry = () => {
    setOpenInquiry(false)
  }

  // function to handle radio buttons
  const handleUpdateDeliver = flg => {
    setDeliver(flg)
  }
  const handleUpdateGender = flg => {
    setGender(flg)
  }
  const handleUpdateSendMailFlg = flg => {
    setSendMailFlg(flg)
  }

  // reget
  const regetLogin = async sess => {
    // for post sending
    let params = new URLSearchParams()
    params.append("sessionId", sess)
    params.append("userAgent", window.navigator.userAgent)
    params.append("API_KEY", process.env.API_KEY)

    const data = await fetchDataPost("user_input/getData.php", params)

    if (data) {
      data.gender = data.gender === undefined ? 1 : data.gender
      data.deli_flg = data.deli_flg === undefined ? 0 : data.deli_flg
      data.send_mail_flg =
        data.send_mail_flg === undefined ? 1 : data.send_mail_flg

      data.goods_id > 0 && inputGoodsId(data.goods_id)
      data.goods_count > 0 && inputGoodsCount(data.goods_count)
      data.client_id > 0 && setClientId(data.client_id)
      data.last_name && setLastName(data.last_name)
      data.first_name && setFirstName(data.first_name)
      data.phone_number && setPhoneNumber(data.phone_number)
      data.mail_address && setMailAddress(data.mail_address)
      data.birthday && setBirthday(data.birthday)
      data.birthday && setBirthYear(data.birthday.split("-")[0])
      data.birthday && setBirthMonth(data.birthday.split("-")[1])
      data.birthday && setBirthDay(data.birthday.split("-")[2])
      handleUpdateGender(data.gender)
      data.zip_code && setZipCode(data.zip_code)
      data.prefecture_id > 0 && setPrefectureId(data.prefecture_id)
      data.address && setAddress(data.address)
      data.address_detail && setAddressDetail(data.deliver_address_detail)
      handleUpdateDeliver(data.deli_flg)
      data.deli_flg > 0 &&
        data.deliver_client_name &&
        setDeliverClientName(data.deliver_client_name)
      data.deli_flg > 0 &&
        data.deliver_zip_code &&
        setDeliverZipCode(data.deliver_zip_code)
      data.deli_flg > 0 &&
        data.deliver_prefecture_id > 0 &&
        setDeliverPrefectureId(data.deliver_prefecture_id)
      data.deli_flg > 0 &&
        data.deliver_address &&
        setDeliverAddress(data.deliver_address)
      data.deli_flg > 0 &&
        data.deliver_address_detail &&
        setDeliverAddressDetail(data.deliver_address_detail)
      data.deli_flg > 0 &&
        data.deliver_phone_number &&
        setDeliverPhoneNumber(data.deliver_phone_number)

      data.client_deliver_date && setDeliverClientDate(data.client_deliver_date)
      data.payment_id > 0 && setPaymentId(data.payment_id)
      data.deliver_id > 0 && setDeliverId(data.deliver_id)

      data.password && setPassword(data.password)
      handleUpdateSendMailFlg(data.send_mail_flg)
      data.comment && setComment(data.comment)
      data.credit_token && setCreditToken(data.credit_token)

      if (data.client_id > 0) {
        setIsLogin(true)
        setClientAddressId(data.client_address_id)

        const newAddress = data.client_address.map(e => ({
          value: e["client_address_id"],
          label: `${e["deliver_client_name"]}（${e["deliver_prefecture_name"]}${
            e["deliver_address"]
          } ${e["deliver_address_detail"].toString()}）`,
        }))
        setClientAddressList(newAddress)
      } else {
        setIsLogin(false)
      }
    }
  }

  // login
  const handleSubmitLogin = async event => {
    event.preventDefault()
    let ersLogin = errorsLogin

    Validations.inputPhoneNumberLogin(
      phoneNumberLogin,
      setPhoneNumberLogin,
      ersLogin,
      setErrorsLogin,
      {}
    )
    Validations.inputPasswordLogin(
      passwordLogin,
      setPasswordLogin,
      ersLogin,
      setErrorsLogin,
      {}
    )

    setIsLoading(true)

    if (ersLogin.phoneNumberLogin === "" && ersLogin.phoneNumberLogin === "") {
      // for post sending
      let params = new URLSearchParams()
      params.append("phoneNumber", phoneNumberLogin)
      params.append("password", passwordLogin)
      params.append("sessionId", sessionId)
      params.append("userAgent", window.navigator.userAgent)
      params.append("API_KEY", process.env.API_KEY)

      // cookie operation
      const data = await fetchDataPost("login/getLogin.php", params)
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)

      if (data.client_id > 0) {
        setIsLogin(true)
        setClientId(data.client_id)
        setLastName(data.last_name)
        setFirstName(data.first_name)
        setPhoneNumber(data.phone_number)
        setMailAddress(data.mail_address)
        setBirthday(data.birthday)
        data.birthday && setBirthYear(data.birthday.split("-")[0])
        data.birthday && setBirthMonth(data.birthday.split("-")[1])
        data.birthday && setBirthDay(data.birthday.split("-")[2])

        setGender(data.gender)
        setZipCode(data.zip_code)
        setPrefectureId(data.prefecture_id)
        setAddress(data.address)
        setAddressDetail(data.address_detail)
        setPassword(data.password)

        ersLogin.clientId = ""
        ersLogin.lastName = ""
        ersLogin.firstName = ""
        ersLogin.phoneNumber = ""
        ersLogin.mailAddress = ""
        ersLogin.birthday = ""
        ersLogin.gender = ""
        ersLogin.zipCode = ""
        ersLogin.prefectureId = ""
        ersLogin.address = ""
        ersLogin.addressDetail = ""
        ersLogin.password = ""

        const newAddress = data.client_address.map(e => ({
          value: e["client_address_id"],
          label: `${e["deliver_client_name"]}（${e["deliver_prefecture_name"]}${
            e["deliver_address"]
          } ${e["deliver_address_detail"].toString()}）`,
        }))
        setClientAddressList(newAddress)
        setClientAddressId(newAddress[0].value)
        ersLogin.clientAddressId = ""

        if (data.receiver_site_id) {
          setSendMailFlg(1)
        } else {
          setSendMailFlg(0)
        }
        ersLogin.notFound = ""
        handleClose()
      } else {
        let ersLogin = errorsLogin
        ersLogin.notFound = "該当するお客様情報が見つかりませんでした"
      }
      setErrorsLogin(ersLogin)
    } else {
      setErrorsLogin(ersLogin)
      setIsLoading(false)
      console.log({ errorsLogin })
    }
  }

  // this form validation
  const handleSubmit = async event => {
    event.preventDefault()
    let params = {}
    let errorCheck = false
    setIsLoadingForm(true)

    Validations.inputLastName(lastName, setLastName, errors, setErrors, {})
    Validations.inputFirstName(firstName, setFirstName, errors, setErrors, {})
    Validations.inputPhoneNumber(
      phoneNumber,
      setPhoneNumber,
      errors,
      setErrors,
      {}
    )
    Validations.inputMailAddress(
      mailAddress,
      setMailAddress,
      errors,
      setErrors,
      {}
    )
    Validations.inputBirthday(birthday, setBirthday, errors, setErrors, {})
    Validations.checkZipCode(zipCode, setZipCode, errors, setErrors, {})
    Validations.inputPrefectureId(
      prefectureId,
      setPrefectureId,
      errors,
      setErrors,
      {}
    )
    Validations.inputAddress(address, setAddress, errors, setErrors, {})
    Validations.inputPaymentId(paymentId, setPaymentId, errors, setErrors, {})
    Validations.inputDeliverClientName(
      deliverClientName,
      setDeliverClientName,
      errors,
      setErrors,
      { deliver: deliver }
    )

    Validations.inputDeliverPhoneNumber(
      deliverPhoneNumber,
      setDeliverPhoneNumber,
      errors,
      setErrors,
      { deliver: deliver }
    )
    Validations.checkDeliverZipCode(
      deliverZipCode,
      setDeliverZipCode,
      errors,
      setErrors,
      {
        deliver: deliver,
      }
    )
    Validations.inputDeliverPrefectureId(
      deliverPrefectureId,
      setDeliverPrefectureId,
      errors,
      setErrors,
      { deliver: deliver }
    )
    Validations.inputDeliverAddress(
      deliverAddress,
      setDeliverAddress,
      errors,
      setErrors,
      { deliver: deliver }
    )
    Validations.inputDeliverId(deliverId, setDeliverId, errors, setErrors, {})
    inputDeliverClientDate(deliverClientDate)
    Validations.inputPassword(password, setPassword, errors, setErrors, {})
    Validations.inputComment(comment, setComment, errors, setErrors, {})
    Validations.inputCardNo(cardNo, setCardNo, errors, setErrors, {
      paymentId: paymentId,
    })
    Validations.inputCardName(cardName, setCardName, errors, setErrors, {
      paymentId: paymentId,
    })
    Validations.inputExpireDate(expireDate, setExpireDate, errors, setErrors, {
      paymentId: paymentId,
    })
    Validations.inputSecurityCode(
      securityCode,
      setSecurityCode,
      errors,
      setErrors,
      {
        paymentId: paymentId,
      }
    )

    // credit
    Validations.inputCreditToken(
      securityCode,
      setSecurityCode,
      errors,
      setErrors,
      {
        paymentId: paymentId,
      }
    )

    // credit
    Validations.inputCreditToken(
      creditToken,
      setCreditToken,
      errors,
      setErrors,
      {
        paymentId: paymentId,
      }
    )

    // main
    if (
      errors.lastName === "" &&
      errors.firstName === "" &&
      errors.phoneNumber === "" &&
      errors.mailAddress === "" &&
      errors.birthday === "" &&
      errors.zipCode === "" &&
      errors.prefectureId === "" &&
      errors.address === "" &&
      errors.paymentId === "" &&
      errors.deliverId === "" &&
      errors.deliverClientDate === "" &&
      errors.password === ""
    ) {
      params.company_id = companyId
      params.client_id = clientId
      params.last_name = lastName
      params.first_name = firstName
      params.phone_number = phoneNumber
      params.mail_address = mailAddress
      params.birthday = birthday
      params.gender = gender
      params.zip_code = zipCode
      params.prefecture_id = prefectureId
      params.address = address
      params.address_detail = addressDetail
      params.payment_id = paymentId
      params.deliver_id = deliverId
      params.client_address_id = clientAddressId
      params.client_deliver_date = deliverClientDate
      params.password = password
      params.send_mail_flg = sendMailFlg
      params.comment = comment
      params.argument_id = arg
      params.argument_data = val
      params.confirm = "1"
      params.back_url = process.env.LP_URL
      params.SESSION = sessionId
    } else {
      errorCheck = true
    }

    params.deli_flg = deliver
    // deliver
    if (deliver > 0) {
      if (
        errors.deliverClientName === "" &&
        errors.deliverZipCode === "" &&
        errors.deliverPrefectureId === "" &&
        errors.deliverAddress === "" &&
        errors.deliverPhoneNumber === ""
      ) {
        params.deliver_client_name = deliverClientName
        params.deliver_zip_code = deliverZipCode
        params.deliver_prefecture_id = deliverPrefectureId
        params.deliver_address = deliverAddress
        params.deliver_address_detail = deliverAddressDetail
        params.deliver_phone_number = deliverPhoneNumber
      } else {
        errorCheck = true
      }
    }

    if (clientAddressId > 0) {
      params.deli_flg = 1
      let addressParams = new URLSearchParams()
      addressParams.append("clientAddressId", clientAddressId)
      addressParams.append("API_KEY", process.env.API_KEY)
      const address_data = await fetchDataPost(
        "client_address/getAddress.php",
        addressParams
      )
      params.deliver_client_name = address_data.deliver_client_name
      params.deliver_zip_code = address_data.deliver_zip_code
      params.deliver_prefecture_id = address_data.deliver_prefecture_id
      params.deliver_address = address_data.deliver_address
      params.deliver_address_detail = address_data.deliver_address_detail
      params.deliver_phone_number = address_data.deliver_phone_number
    }

    params.agreement_flg = 1

    // credit
    if (paymentId === "6") {
      if (errors.creditToken === "") {
        params.token = creditToken
      } else {
        errorCheck = true
      }
    }

    if (errorCheck === false) {
      params.userAgent = window.navigator.userAgent

      const form = document.createElement("form")
      form.method = "POST"
      form.action = process.env.CONFIRM_URL

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input")
          hiddenField.type = "hidden"
          hiddenField.name = key
          hiddenField.value = params[key]
          form.appendChild(hiddenField)
        }
      }
      document.body.appendChild(form)
      form.submit()
    } else {
      console.log("-------------------")
      console.log({ errors })
    }
    setTimeout(() => {
      setIsLoadingForm(false)
      errorCheck === false ? setIsOk(true) : setIsOk(false)
    }, 1000)
  }

  // check Holiday from mst_holiday
  function disableWeekends(date) {
    const checkDate = moment(date).format("YYYY-MM-DD")
    return (
      //      date.getDay() === 0 ||
      //      date.getDay() === 6 ||
      holidayList.includes(checkDate)
    )
  }

  // jsx
  return (
    <Paper elevation={5}>
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          maxWidth: "lg",
        }}
      >
        <h2 className="text-center text-neutral-600 font-bold text-3xl">
          ご購入フォーム
        </h2>
        <Login
          isLogin={isLogin}
          isLoading={isLoading}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleSubmitLogin={handleSubmitLogin}
          phoneNumberLogin={phoneNumberLogin}
          passwordLogin={passwordLogin}
          setPhoneNumberLogin={setPhoneNumberLogin}
          setPasswordLogin={setPasswordLogin}
          errorsLogin={errorsLogin}
          setErrorsLogin={setErrorsLogin}
        />
        <DividerTitle title="ご希望の商品" />
        <form
          method="POST"
          onSubmit={handleSubmit}
          action={process.env.CONFIRM_URL}
          name="frm"
        >
          {/* goods */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2} className="pb-1">
              <Label label="商品" required={true} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={7} className="pb-1">
              <BoxSelect
                fullWidth={true}
                label={"バリエーション"}
                required={true}
                select={inputGoodsId}
                inputName="goodsId"
                value={goodsId}
                options={goodsList}
                setValue={setGoodsId}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <BoxSelect
                fullWidth={true}
                label={"数量"}
                required={true}
                select={inputGoodsCount}
                inputName="goodsCount"
                value={goodsCount}
                options={Config.counts}
                setValue={setGoodsCount}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerTitle title="お客様情報" />
          {/* name */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2} justifyContent="flex-end">
              <Label label="名前" required={true} fullWidth={true} />
            </Grid>
            <Grid item xs={6} md={5}>
              <InputText
                fullWidth={true}
                label={"姓"}
                multiline={false}
                required={true}
                onChange={Validations.inputLastName}
                rows={1}
                type={"text"}
                inputName="lastName"
                value={lastName}
                setValue={setLastName}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>

            <Grid item xs={6} md={5}>
              <InputText
                fullWidth={true}
                label={"名"}
                multiline={false}
                required={true}
                onChange={Validations.inputFirstName}
                rows={1}
                type={"text"}
                inputName="firstName"
                value={firstName}
                setValue={setFirstName}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerHr />
          {/* phone_number */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2} justifyContent="flex-end">
              <Label label="電話番号" required={true} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <InputText
                fullWidth={true}
                multiline={false}
                required={true}
                onChange={Validations.inputPhoneNumber}
                rows={1}
                type={"tel"}
                inputName="phoneNumber"
                value={phoneNumber}
                maxLength={11}
                helperText="例：09012345678"
                setValue={setPhoneNumber}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerHr />
          {/* mail_address */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2}>
              <Label label="Eメール" required={true} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <InputText
                fullWidth={true}
                multiline={false}
                required={true}
                onChange={Validations.inputMailAddress}
                rows={1}
                inputName="mailAddress"
                value={mailAddress}
                type={"email"}
                helperText="入力に誤りがありますと注文・発送完了メールが届きません。ご注意ください。"
                setValue={setMailAddress}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerHr />
          {/* birthday */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2}>
              <Label label="生年月日" required={false} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <SelectBirthday
                fullWidth={false}
                required={false}
                onChange={Validations.inputBirthday}
                inputName="birthday"
                value={birthday}
                birthYear={birthYear}
                birthMonth={birthMonth}
                birthDay={birthDay}
                setBirthYear={setBirthYear}
                setBirthMonth={setBirthMonth}
                setBirthDay={setBirthDay}
                setValue={setBirthday}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerHr />
          {/* gender */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2}>
              <Label label="性別" required={true} fullWidth={true} />
            </Grid>

            <Grid item xs={12} md={10}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="gender"
                  defaultValue={gender}
                  value={gender}
                  onChange={event => setGender(event.target.value)}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="男性"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="女性"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <DividerHr />
          {/* 購入者住所 */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2} className="borer-1">
              <Label label="購入者住所" required={true} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputText
                fullWidth={true}
                label={"郵便番号"}
                multiline={false}
                required={true}
                onChange={Validations.inputZipCode}
                rows={1}
                inputName="zipCode"
                value={zipCode}
                type={"tel"}
                maxLength={7}
                helperText="例：1600023"
                setValue={setZipCode}
                errors={errors}
                setErrors={setErrors}
                options={{
                  prefectureId: prefectureId,
                  address: address,
                  setPrefectureId: setPrefectureId,
                  setAddress: setAddress,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <BoxSelect
                fullWidth={true}
                label={"都道府県"}
                required={true}
                select={Validations.inputPrefectureId}
                inputName="prefectureId"
                value={prefectureId}
                options={prefectureList}
                helperText=" "
                setValue={setPrefectureId}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>

            <Grid item xs={12} md={2} className="borer-1"></Grid>
            <Grid item xs={12} md={5}>
              <InputText
                fullWidth={true}
                label={"住所"}
                multiline={false}
                required={true}
                onChange={Validations.inputAddress}
                rows={2}
                inputName="address"
                value={address}
                type={"text"}
                helperText="例：新宿区西新宿７－１－１"
                setValue={setAddress}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputText
                fullWidth={true}
                label={"アパート・マンション"}
                multiline={false}
                required={false}
                onChange={Validations.inputAddressDetail}
                rows={2}
                inputName="addressDetail"
                value={addressDetail}
                type={"text"}
                helperText="例：西新宿ビル１０１"
                setValue={setAddressDetail}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerTitle title="支払方法" />

          {/* payment_id */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid
              item
              xs={12}
              md={2}
              justifyContent="flex-end"
              className="pb-1"
            >
              <Label label="支払方法" required={true} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <BoxSelect
                fullWidth={true}
                select={Validations.inputPaymentId}
                inputName="paymentId"
                value={paymentId}
                options={paymentList}
                setValue={setPaymentId}
                errors={errors}
                setErrors={setErrors}
              />

              <Payment
                paymentId={paymentId}
                userId={mailAddress}
                sessionId={sessionId}
                errors={errors}
                setErrors={setErrors}
                cardNo={cardNo}
                cardName={cardName}
                expireDate={expireDate}
                securityCode={securityCode}
                setCardNo={setCardNo}
                setCardName={setCardName}
                setExpireDate={setExpireDate}
                setSecurityCode={setSecurityCode}
                inputCardNo={Validations.inputCardNo}
                inputCardName={Validations.inputCardName}
                inputExpireDate={Validations.inputExpireDate}
                inputSecurityCode={Validations.inputSecurityCode}
                setCreditToken={setCreditToken}
              />
            </Grid>
          </Grid>
          <DividerHr />
          {/* client_address_id */}
          {clientId > 0 && (
            <>
              <Grid container columnSpacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={2} justifyContent="flex-end">
                  <Label label="配送先" required={true} fullWidth={true} />
                  <Address
                    isLoading={isLoadingAddress}
                    open={openAddress}
                    handleOpen={handleOpenAddress}
                    handleClose={handleCloseAddress}
                    clientId={clientId}
                    clientAddressList={clientAddressList}
                    prefectureList={prefectureList}
                    setClientAddressList={setClientAddressList}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <SelectBox
                    fullWidth={true}
                    label={"配送先"}
                    required={false}
                    select={setClientAddressId}
                    value={clientAddressId}
                    options={clientAddressList}
                    error={errors.clientAddressId}
                  />
                </Grid>
              </Grid>
              <DividerHr />
            </>
          )}

          {/* deliver */}
          {clientId < 1 && (
            <>
              <Grid container columnSpacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={2}>
                  <Label label="配送先" required={true} fullWidth={true} />
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="deliver"
                      defaultValue={deliver}
                      value={deliver}
                      onChange={event => setDeliver(event.target.value)}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="購入者住所と同じ"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="別の住所へ送る"
                      />
                    </RadioGroup>
                  </FormControl>

                  <p className="text-xs mb-1">
                  ※郵便局・コンビニ・佐川営業所止めは受付出来ません。
                  </p>
                  <p className="text-xs mb-1">
                  ※ヤマト運輸の営業所止めには対応しております。ご希望の際は「ご指定の営業所」のご記入、またはその他ご希望の欄へ「最寄りの営業所止め希望」とご記入下さい。
                  </p>
                </Grid>
              </Grid>
              <DividerHr />
            </>
          )}
          {deliver > 0 && clientId < 1 && (
            <>
              {/* deliver_client_name */}
              <Grid container columnSpacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={2}>
                  <Label label="配送先名" required={true} fullWidth={true} />
                </Grid>
                <Grid item xs={12} md={10}>
                  <InputText
                    fullWidth={true}
                    multiline={false}
                    required={true}
                    onChange={Validations.inputDeliverClientName}
                    rows={1}
                    inputName="deliverClientName"
                    value={deliverClientName}
                    type={"text"}
                    setValue={setDeliverClientName}
                    errors={errors}
                    setErrors={setErrors}
                    options={{ deliver: deliver }}
                  />
                </Grid>
              </Grid>

              {/* deliver_client_name */}
              <Grid container columnSpacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={2}>
                  <Label
                    label="配送先電話番号"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <InputText
                    fullWidth={true}
                    multiline={false}
                    required={true}
                    onChange={Validations.inputDeliverPhoneNumber}
                    rows={1}
                    inputName="deliverPhoneNumber"
                    value={deliverPhoneNumber}
                    type={"tel"}
                    maxLength={11}
                    helperText="例：09012345678"
                    setValue={setDeliverPhoneNumber}
                    errors={errors}
                    setErrors={setErrors}
                    options={{ deliver: deliver }}
                  />
                </Grid>
              </Grid>

              {/*配送先住所 */}
              <Grid container columnSpacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={2} className="borer-1">
                  <Label label="配送先住所" required={true} fullWidth={true} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <InputText
                    fullWidth={true}
                    label={"郵便番号"}
                    multiline={false}
                    required={true}
                    onChange={Validations.inputDeliverZipCode}
                    rows={1}
                    inputName="deliverZipCode"
                    value={deliverZipCode}
                    type={"tel"}
                    maxLength={7}
                    helperText="例：1600023"
                    setValue={setDeliverZipCode}
                    errors={errors}
                    setErrors={setErrors}
                    options={{
                      deliver: deliver,
                      deliverPrefectureId: deliverPrefectureId,
                      deliverAddress: deliverAddress,
                      setDeliverPrefectureId: setDeliverPrefectureId,
                      setDeliverAddress: setDeliverAddress,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <BoxSelect
                    fullWidth={true}
                    label={"都道府県"}
                    required={true}
                    select={Validations.inputDeliverPrefectureId}
                    inputName="deliverPrefectureId"
                    value={deliverPrefectureId}
                    options={prefectureList}
                    setValue={setDeliverPrefectureId}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </Grid>

                <Grid item xs={12} md={2} className="borer-1"></Grid>
                <Grid item xs={12} md={5}>
                  <InputText
                    fullWidth={true}
                    label={"住所"}
                    multiline={false}
                    required={true}
                    onChange={Validations.inputDeliverAddress}
                    rows={2}
                    inputName="deliverAddress"
                    value={deliverAddress}
                    type={"text"}
                    helperText="例：新宿区西新宿７－１－１"
                    setValue={setDeliverAddress}
                    errors={errors}
                    setErrors={setErrors}
                    options={{ deliver: deliver }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <InputText
                    fullWidth={true}
                    label={"アパート・マンション"}
                    multiline={false}
                    required={false}
                    onChange={Validations.inputDeliverAddressDetail}
                    rows={2}
                    inputName="deliverAddressDetail"
                    value={deliverAddressDetail}
                    type={"text"}
                    helperText="例：西新宿ビル１０１"
                    setValue={setDeliverAddressDetail}
                    errors={errors}
                    setErrors={setErrors}
                    options={{ deliver: deliver }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <DividerTitle title="オプション" />
          {/* deliver_client_date */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2} className="mb-2">
              <Label label="お届け希望日" required={false} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  cancelText="キャンセル"
                  required={false}
                  clearable={true}
                  clearText="希望日を指定しない"
                  toolbarPlaceholder="配送希望日を選択"
                  inputFormat="yyyy/MM/dd"
                  defaultValue=""
                  value={deliverClientDate}
                  minDate={new Date(deliverStartDate)}
                  maxDate={new Date(deliverEndDate)}
                  disablePast
                  shouldDisableDate={disableWeekends}
                  onChange={inputDeliverClientDate}
                  renderInput={({ inputProps, error, label }) => (
                    <TextField {...inputProps} error={false} />
                  )}
                />
              </LocalizationProvider>
              <p className="text-xs my-1">
                お届け日のご指定はご注文後、{Config.deliverStartCount}日～
                {Config.deliverEndCount - Config.deliverStartCount}
                日以内となります。
              </p>
              <p className="text-xs mb-1">
              大型連休(年末年始・GW・夏季休暇)、またはお届け先が離島などの場合はご希望に添えない可能性もございます。ご了承下さい。
              </p>
              <p className="text-xs mb-1 text-orange-500">
              ※最短配送ご希望の場合は、日時指定をしないで下さい
              </p>
            </Grid>
          </Grid>
          <DividerHr />
          {/* deliver_id */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }} className="my-5">
            <Grid
              item
              xs={12}
              md={2}
              justifyContent="flex-end"
              className="pb-1"
            >
              <Label label="配送時間" required={false} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <BoxSelect
                fullWidth={true}
                required={false}
                select={Validations.inputDeliverId}
                inputName="deliverId"
                value={deliverId}
                options={deliverList}
                setValue={setDeliverId}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerHr />
          {/* password */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2}>
              <Label label="パスワード" required={false} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <InputText
                fullWidth={true}
                multiline={false}
                required={false}
                onChange={Validations.inputPassword}
                rows={1}
                inputName="password"
                value={password}
                type={"password"}
                maxLength={20}
                helperText="半角英数字6文字以上20文字以内（記号は含めない）"
                setValue={setPassword}
                errors={errors}
                setErrors={setErrors}
              />
              <p className="text-xs mb-1">
                設定して頂くとログインをすることにより次回以降の購入がスムーズになります。
              </p>
            </Grid>
          </Grid>
          <DividerHr />
          {/* send_mail_flg */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2}>
              <Label label="メールマガジン" required={false} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="sendMailFlg"
                  defaultValue={sendMailFlg}
                  value={sendMailFlg}
                  onChange={event => setSendMailFlg(event.target.value)}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="購読する"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="購読しない"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <DividerHr />
          {/* comment */}
          <Grid container columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={2}>
              <Label label="その他ご希望" required={false} fullWidth={true} />
            </Grid>
            <Grid item xs={12} md={10}>
              <p className="text-xs my-1">
                最寄りの営業所止め希望の方はこちらにご記入してください。
              </p>
              <p className="text-xs my-1">
                ※ヤマト営業所のみご指定いただけます
              </p>
              <p className="text-xs my-1">
                置き配は<span class="text-orange-500">ヤマトクロネコメンバーズ会員のみ</span>ご利用可能。
              </p>
              <p className="text-xs my-1">
                ※ご利用方法はヤマト運輸へお問い合わせください。(当店では対応致しかねます)
              </p>
              <InputText
                fullWidth={true}
                multiline={true}
                required={false}
                onChange={Validations.inputComment}
                rows={3}
                inputName="comment"
                value={comment}
                type={"text"}
                setValue={setComment}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
          </Grid>
          <DividerHr />
          <div className="text-center mt-5">
            {isOk === false && (
              <p className="text-red-500 font-bold">
                入力内容に不備があります。
              </p>
            )}
            {isLoadingForm === true && <Loading />}
            {isLoadingForm === false && (
              <input
                className="m-auto"
                type="image"
                src={CartButton}
                alt="入力内容の確認"
                onClick={handleSubmit}
              />
            )}
          </div>
        </form>
        <Inquiry
          open={openInquiry}
          handleOpen={handleOpenInquiry}
          handleClose={handleCloseInquiry}
          clientId={clientId}
          lastName={lastName}
          firstName={firstName}
          mailAddress={mailAddress}
        />
      </Box>
    </Paper>
  )
}

export default Form
