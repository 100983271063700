import React from "react"
import FormLabel from "@mui/material/FormLabel"

const Label = props => {
  const fontBold = props.required ? " font-bold" : ""
  return (
    <FormLabel
      component="legend"
      required={props.required}
      className={`md:mt-2 text-md lg:text-lg lg:text-right${fontBold}`}
    >
      {props.label}
    </FormLabel>
  )
}

export default Label
