import React from "react"
import Sift from "sift-react"

const SiftSend = props => {
  const { sessionId, userId } = props

  return (
    <Sift
      userId={userId}
      sessionId={sessionId}
      beaconKey={process.env.SIFT_BEAKON_KEY}
    />
  )
}

export default SiftSend
