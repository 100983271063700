import React from "react"

const DividerHr = () => {
  return (
    <hr
      style={{
        background: "#fff",
        width: "100%",
        margin: "10px auto",
        borderTop: "1px dashed #aaa",
      }}
    />
  )
}
export default DividerHr
