import React, {useState} from "react"
import { Config } from "../../site.config"
import { StaticImage } from "gatsby-plugin-image"
import Delivery from "./Delivery"
import { FcUndo, FcInTransit } from "react-icons/fc";

const CartSm = props => {
  const { inputGoodsId, Scroll, company } = props
  const [openDelivery, setOpenDelivery] = useState(false)
  const [deliveryRef, setDeliveryRef] = useState('all')

  const handleOpenDelivery = (deliveryRef) => {
    setDeliveryRef(deliveryRef)
    setOpenDelivery(true)
  }

  const handleCloseDelivery = () => {
    setOpenDelivery(false)
  }

  return (
    <div>
      <StaticImage
        width={960}
        height={888}
        src={"../assets/images/sm/f_A04.png"}
        alt={"どんな助成もオトしてしまうフェロモン香水"}
        placeholder="blurred"
        quality="65"
      />
      <StaticImage
        width={960}
        height={257}
        src={"../assets/images/sm/f_A05.png"}
        alt={"2箱以上のご購入でお得に！"}
        placeholder="blurred"
        quality="65"
      />
      <div className="recommend py-2">
        <div className="grid grid-cols-2 gap-0 m-0 p-0">
          <div className="cursor-pointer" onClick={() =>handleOpenDelivery('refund')}>
            <span className="text-xl inline-block align-top"><FcUndo /></span>
            <span className="text-md font-semibold inline-block text-white">&nbsp;返品について</span>
          </div>
          <div className="cursor-pointer" onClick={() =>handleOpenDelivery('delivery')}>
            <span className="text-xl inline-block align-top"><FcInTransit /></span>
            <span className="text-md font-semibold inline-block text-white">&nbsp;配送について</span>
          </div>
        </div>
        <Delivery
          open={openDelivery}
          handleClose={handleCloseDelivery}
          company={company}
          deliveryRef={deliveryRef}
        />
      </div>

      <div id="offer" style={{ lineHeight: 0, margin: "0 auto -1px" }}>
        <Scroll to="cartForm" smooth={true} duration={600}>
          <StaticImage
            src={"../assets/images/sm/f_A06cv03.png"}
            width={960}
            height={340}
            placeholder="blurred"
            quality="65"
            alt="ご注文はコチラ"
            onClick={() => inputGoodsId(Config.productInfo[0].id)}
            style={{ cursor: "pointer" }}
          />
        </Scroll>
      </div>

      <div style={{ lineHeight: 0, margin: "0 auto -1px" }}>
        <Scroll to="cartForm" smooth={true} duration={600}>
          <StaticImage
            src={"../assets/images/sm/f_A07cv03.png"}
            width={960}
            height={409}
            placeholder="blurred"
            quality="65"
            alt="ご注文はコチラ"
            onClick={() => inputGoodsId(Config.productInfo[1].id)}
            style={{ cursor: "pointer" }}
          />
        </Scroll>
      </div>
      <div style={{ lineHeight: 0, margin: "0 auto -1px" }}>
        <Scroll to="cartForm" smooth={true} duration={600}>
          <StaticImage
            src={"../assets/images/sm/f_A08cv03.png"}
            width={960}
            height={410}
            placeholder="blurred"
            quality="65"
            alt="ご注文はコチラ"
            onClick={() => inputGoodsId(Config.productInfo[2].id)}
            style={{ cursor: "pointer" }}
          />
        </Scroll>
      </div>
      <div style={{ lineHeight: 0, margin: "0 auto -1px" }}>
        <Scroll to="cartForm" smooth={true} duration={600}>
          <StaticImage
            src={"../assets/images/sm/f_A09cv03.png"}
            width={960}
            height={381}
            placeholder="blurred"
            quality="65"
            alt="ご注文はコチラ"
            onClick={() => inputGoodsId(Config.productInfo[3].id)}
            style={{ cursor: "pointer" }}
          />
        </Scroll>
      </div>
      <a href={`tel:${company.phone}`}>
        <StaticImage
          width={960}
          height={359}
          src={"../assets/images/sm/f_A10.png"}
          alt={"お電話でのご注文はコチラ"}
          placeholder="blurred"
          quality="65"
        />
      </a>
    </div>
  )
}

export default CartSm
