import React, { useState, useEffect } from "react"
import Cards from "react-credit-cards"
import Grid from "@mui/material/Grid"
import FormHelperText from "@mui/material/FormHelperText"
import { InputTextCredit } from "./index"
import SiftSend from "./SiftSend"
import "react-credit-cards/es/styles-compiled.css"

const Payment = props => {
  const paymentId = props.paymentId
  const sessionId = props.sessionId
  const userId = props.userId
  const errors = props.errors
  const setErrors = props.setErrors
  const cardNo = props.cardNo
  const cardName = props.cardName
  const expireDate = props.expireDate
  const securityCode = props.securityCode
  const setCardNo = props.setCardNo
  const setCardName = props.setCardName
  const setExpireDate = props.setExpireDate
  const setSecurityCode = props.setSecurityCode
  const inputCardNo = props.inputCardNo
  const inputCardName = props.inputCardName
  const inputExpireDate = props.inputExpireDate
  const inputSecurityCode = props.inputSecurityCode
  const setCreditToken = props.setCreditToken
  const [focus, setFocus] = useState("")

  // credit token
  const strFunction = `
    Multipayment.init('${process.env.GMO_API_KEY}');
    return new Promise(function (resolve, reject) {
        Multipayment.getToken(
            {
                cardno: cNo,
                holdername:cName,
                expire: exDate,
                securitycode: secCode,
            }, resolve
        );
    });
  `
  let getCreateCardToken = new Function(
    "cNo",
    "cName",
    "exDate",
    "secCode",
    strFunction
  )

  const startCreateCard = async (cNo, cName, exDate, secCode) => {
    let ers = errors
    if (
      cNo &&
      errors.cardNo === "" &&
      cName &&
      errors.cardName === "" &&
      exDate &&
      errors.expireDate === "" &&
      secCode &&
      errors.securityCode === ""
    ) {
      const exDateChk = exDate.substr(2, 2) + exDate.substr(0, 2)
      const ret = await getCreateCardToken(
        cNo,
        cName.toUpperCase(),
        exDateChk,
        secCode
      )

      if (ret && ret["resultCode"] === "000") {
        ers.creditToken = ""
        setErrors(ers)
        setCreditToken(ret.tokenObject.token)
      } else {
        switch (ret["resultCode"]) {
          case 100:
            setErrors({
              ...errors,
              creditToken: "カード番号必須チェックエラー",
            })
            break
          case 101:
            setErrors({
              ...errors,
              creditToken: "カード番号フォーマットエラー(数字以外を含む)",
            })
            break
          case 102:
            setErrors({
              ...errors,
              creditToken: "カード番号フォーマットエラー(10-16 桁の範囲外)",
            })
            break
          case 110:
            setErrors({ ...errors, creditToken: "有効期限必須チェックエラー" })
            break
          case 111:
            setErrors({
              ...errors,
              creditToken: "有効期限フォーマットエラー(数字以外を含む)",
            })
            break
          case 112:
            setErrors({
              ...errors,
              creditToken: "有効期限フォーマットエラー(6 又は 4 桁以外)",
            })
            break
          case 113:
            setErrors({
              ...errors,
              creditToken: "有効期限フォーマットエラー(月が 13 以上)",
            })
            break
          case 121:
            setErrors({
              ...errors,
              creditToken:
                "セキュリティコードフォーマットエラー(数字以外を含む)",
            })
            break
          case 122:
            setErrors({
              ...errors,
              creditToken: "セキュリティコードフォーマットエラー(5 桁以上)",
            })
            break
          case 131:
            setErrors({
              ...errors,
              creditToken:
                "名義人フォーマットエラー(半角英数字、一部の記号以外を含む)",
            })
            break
          case 132:
            setErrors({
              ...errors,
              creditToken: "名義人フォーマットエラー(51 桁以上)",
            })
            break
          case 141:
            setErrors({
              ...errors,
              creditToken: "発行数フォーマットエラー(数字以外を含む)",
            })
            break
          case 142:
            setErrors({
              ...errors,
              creditToken: "発行数フォーマットエラー(1-10 の範囲外)",
            })
            break
          case 501:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(id)が送信されていない",
            })
            break
          case 502:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(id)がマスターに存在しない",
            })
            break
          case 511:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(cardInfo)が送信されていない",
            })
            break
          case 512:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(cardInfo)が復号できない",
            })
            break
          case 521:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(key)が送信されていない",
            })
            break
          case 522:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(key)が復号できない",
            })
            break
          case 531:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(callBack)が送信されていない",
            })
            break
          case 541:
            setErrors({
              ...errors,
              creditToken: "トークン用パラメータ(hash)が存在しない",
            })
            break
          case 551:
            setErrors({
              ...errors,
              creditToken: "トークン用 apikey が存在しない ID",
            })
            break
          case 552:
            setErrors({
              ...errors,
              creditToken: "トークン用 apikey が有効ではない",
            })
            break
          case 553:
            setErrors({ ...errors, creditToken: "トークンが利用済みである" })
            break
          case 901:
            setErrors({
              ...errors,
              creditToken: "マルチペイメント内部のシステムエラー",
            })
            break
          default:
            console.log("not match")
        }
        setCreditToken("")
      }
    }
  }

  // ----------------------------------------------------
  // useEffect
  // ----------------------------------------------------
  useEffect(() => {
    getCreateCardToken = new Function(
      "cNo",
      "cName",
      "exDate",
      "secCode",
      strFunction
    )
  }, [])

  useEffect(() => {
    startCreateCard(cardNo, cardName, expireDate, securityCode)
  }, [cardNo, cardName, expireDate, securityCode])

  return (
    <>
      {paymentId === "1" && (
        <div>
          <p className="text-xs">ヤマト運輸にて発送致します。</p>
        </div>
      )}

      {paymentId === "4" && (
        <div>
          <p className="text-sm mb-0">
            ○請求書は商品に同封されていますので、ご確認ください。
          </p>
          <p className="text-xs mb-2">
            注文者様のご住所とお届け先のご住所が異なる場合は、
            請求書は商品に同封されず、購入者様へお送りいたします。
            商品代金のお支払いは「コンビニ」「郵便局」「銀行」「LINE
            Pay」どこでも可能です。
            請求書の記載事項に従って発行日から14日以内にお支払いください
          </p>
          <p className="text-sm mb-0">○ご注意：後払い手数料：220円 （税込）</p>
          <p className="text-xs mb-0">
            後払いのご注文には、株式会社ネットプロテクションズの後払いサービスが適用され、
            同社へ代金債権を譲渡します。
            <a
              href="https://np-atobarai.jp/terms/atobarai-buyer.html"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              NP後払い利用規約及び同社のプライバシーポリシー
            </a>
            に同意して、 後払いサービスをご選択ください。
            ご利用限度額は累計残高で55,000円（税込）迄です。
            詳細はバナーをクリックしてご確認下さい。
            ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。
          </p>
          <p className="mb-1">
            <a
              href="http://www.np-atobarai.jp/about/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://shopping.body-custom.net/img/wiz_newbanner468x64.png"
                alt="NP後払い"
                className="max-w-full"
              />
            </a>
          </p>
        </div>
      )}

      {paymentId === "6" && (
        <>
          <Grid container spacing={2} className="my-3">
            <Grid item xs={12} md={6}>
              <InputTextCredit
                fullWidth={true}
                label={"クレジットカード番号"}
                multiline={false}
                required={true}
                onChange={inputCardNo}
                rows={1}
                inputName="cardNo"
                value={cardNo}
                type={"tel"}
                maxLength={16}
                creditParam={"number"}
                onFocus={setFocus}
                helperText="例：1234567890123456"
                setValue={setCardNo}
                errors={errors}
                setErrors={setErrors}
                options={{ paymentId: paymentId }}
              />
              <InputTextCredit
                fullWidth={true}
                label={"クレジットカード名義"}
                multiline={false}
                required={true}
                onChange={inputCardName}
                rows={1}
                inputName="cardName"
                value={cardName}
                type={"text"}
                creditParam={"name"}
                onFocus={setFocus}
                helperText="例：taro yamada またはTARO YAMADA"
                setValue={setCardName}
                errors={errors}
                setErrors={setErrors}
                options={{ paymentId: paymentId }}
              />
              <InputTextCredit
                fullWidth={true}
                label={"有効期限"}
                multiline={false}
                required={true}
                onChange={inputExpireDate}
                rows={1}
                inputName="expireDate"
                value={expireDate}
                type={"tel"}
                maxLength={4}
                creditParam={"expiry"}
                onFocus={setFocus}
                helperText="例：25年8月 → 0825"
                setValue={setExpireDate}
                errors={errors}
                setErrors={setErrors}
                options={{ paymentId: paymentId }}
              />
              <InputTextCredit
                fullWidth={true}
                label={"セキュリティコード"}
                multiline={false}
                required={true}
                onChange={inputSecurityCode}
                rows={1}
                inputName="securityCode"
                value={securityCode}
                type={"tel"}
                maxLength={4}
                creditParam={"cvc"}
                onFocus={setFocus}
                helperText="例：123"
                setValue={setSecurityCode}
                errors={errors}
                setErrors={setErrors}
                options={{ paymentId: paymentId }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Cards
                number={cardNo}
                name={cardName}
                expiry={expireDate}
                cvc={securityCode}
                focused={focus}
              />
              <FormHelperText className="text-red-600">
                {props.errors.creditToken}
              </FormHelperText>
            </Grid>
          </Grid>
          <SiftSend sessionId={sessionId} userId={userId} />
        </>
      )}
    </>
  )
}

export default Payment
