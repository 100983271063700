import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { CookiesProvider } from "react-cookie"
import { useQueryParam, StringParam } from "use-query-params"
import Form from "../components/Form"
import { Config } from "../../site.config"
import getUniqId from "../libs/getUniqId"
import getUserAgent from "../libs/getUserAgent"
import getParameter from "../libs/getParameter"
import addAccessCount from "../libs/addAccessCount"
import { fetchData, fetchDataPost } from "../libs/fetchers"
import { Link as Scroll } from "react-scroll"
import MainPc from "../components/MainPc"
import MainSm from "../components/MainSm"
import Footer from "../components/Footer"
import TagSc from "../components/tags/Sc"

const apiUrl = `${process.env.API_COMPANY_URL}?ref=${process.env.PREFIX}`

const Top = ({ data }) => {
  // ----------------------------------------------------
  // useState
  // ----------------------------------------------------
  const [session, setSession] = useQueryParam("session_id", StringParam)
  const [companyId, setCompanyId] = useState(Config.companyId)
  const [company, setCompany] = useState({})
  const [sessionId, setSessionId] = useState(session || "")
  const [arg, setArg] = useState("")
  const [val, setVal] = useState("")
  const [device, setDevice] = useState(0)
  const [goodsId, setGoodsId] = useState("")
  const [goodsCount, setGoodsCount] = useState(Config.counts[0].value)
  const [goodsList, setGoodsList] = useState([])

  // ----------------------------------------------------
  // functions
  // ----------------------------------------------------
  // goodsId
  const inputGoodsId = value => {
    setGoodsId(value)
  }

  // goodsCount
  const inputGoodsCount = value => {
    setGoodsCount(value)
  }

  // post for user input API
  const createInput = async (sess, arg, val) => {
    let params = new URLSearchParams()
    params.append("companyId", companyId)
    params.append("deliverTraderId", Config.deliverTraderId)
    params.append("sessionId", sess)
    params.append("arg", arg)
    params.append("val", val)
    params.append("purchaseMethod", Config.purchaseMethod)
    params.append("userAgent", window.navigator.userAgent)
    params.append("API_KEY", process.env.API_KEY)

    const data = await fetchDataPost("user_input/createInput.php", params)
    return data
  }

  // post for cart API
  const createCart = async (sess, gid, cnt) => {
    let params = new URLSearchParams()
    params.append("sessionId", sess)
    params.append("goodsId", gid)
    params.append("goodsCount", cnt)
    //params.append("ipAddress", userInfo.ip)
    params.append("API_KEY", process.env.API_KEY)

    // throw cart API
    const data = await fetchDataPost("ope_cart/createCart.php", params)
    return data // error data if exists
  }

  // ----------------------------------------------------
  // fetchers
  // ----------------------------------------------------
  // get goods all for select menu (useEffect)
  const getGoodsList = async goods_header_id => {
    const data = await fetchData("goods/getList.php", {
      goods_header_id: goods_header_id,
    })
    const newData = data.map(e => ({
      value: e["goods_id"],
      label: e["goods_name"] + e["color_name"] + e["size_name"],
    }))
    !session && inputGoodsId(newData[0].value)
    setGoodsList(newData)
    return newData
  }

  useEffect(() => {
    const dev = getUserAgent()
    setDevice(dev)

    let sess = sessionStorage.getItem("session_id")
    if (!sess) {
      sess = getUniqId()
      sessionStorage.setItem("session_id", sess)
    }
    setSessionId(sess)

    getParameter()
    let arg = sessionStorage.getItem("arg")
    let val = sessionStorage.getItem("val")

    if (arg !== "") {
      setArg(arg)
    }
    if (val !== "") {
      setVal(val)
    }
    fetch(apiUrl)
      .then(x => x.json())
      .then(x => setCompany(x))
    addAccessCount(dev)

    getGoodsList(Config.productId).then(glist => {
      !session && setGoodsId(glist[0].value)
      !session && setGoodsCount(1)
    })
    // create user input
    createInput(sess, arg, val)
  }, [])

  useEffect(() => {
    const sess = sessionStorage.getItem("session_id")
    createCart(sess, goodsId, goodsCount)
  }, [goodsId, goodsCount])

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "ja-jp" }}
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: `description`,
            content: data.site.siteMetadata.description,
          },
          {
            name: `google-site-verification`,
            content: "AFfzWfoEGxSL596ICFOgIjB7FIl7ThuXnqB5pEpeZLc",
          },
        ]}
      />
      <TagSc />

      <div className="App">
        {device > 0 ? (
          <MainSm
            inputGoodsId={inputGoodsId}
            Scroll={Scroll}
            company={company}
          />
        ) : (
          <MainPc inputGoodsId={inputGoodsId} Scroll={Scroll} company={company} />
        )}
      </div>
      <div id="cartForm" className="max-w-screen-lg mx-auto my-3">
        <CookiesProvider>
          <Form
            companyId={companyId}
            sessionId={sessionId}
            arg={arg}
            val={val}
            goodsId={goodsId}
            goodsCount={goodsCount}
            setGoodsId={setGoodsId}
            setGoodsCount={setGoodsCount}
            inputGoodsId={inputGoodsId}
            inputGoodsCount={inputGoodsCount}
            goodsList={goodsList}
          />
        </CookiesProvider>
      </div>
      <Footer company={company} siteData={data.site.siteMetadata} />
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        copyright
      }
    }
  }
`

export default Top
