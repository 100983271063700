import validator from "validator"
import moment from "moment"
import { fetchPostalCodes } from "./fetchers"

export const inputGoodsId = (value, setValue, errors, setError, options) => {
  let ers = errors
  const errorMessage = value < 1 ? "未選択です" : ""
  ers.goodsId = errorMessage
  setError(ers)
  setValue(value)
}

export const inputGoodsCount = (value, setValue, errors, setError, options) => {
  let ers = errors
  const errorMessage = value < 1 ? "未選択です" : ""
  ers.goodsCount = errorMessage
  setError(ers)
  setValue(value)
}

export const inputLastName = (value, setValue, errors, setError, options) => {
  let ers = errors
  const errorMessage = !value ? "未入力です" : ""
  ers.lastName = errorMessage
  setError(ers)
  setValue(value)
}

export const inputFirstName = (value, setValue, errors, setError, options) => {
  let ers = errors
  const errorMessage = !value ? "未入力です" : ""
  ers.firstName = errorMessage
  setError(ers)
  setValue(value)
}

export const inputPhoneNumber = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  const input = value ? value.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, "") : ""

  if (!input) {
    const errorMessage = "未入力です"
    ers.phoneNumber = errorMessage
    setError(ers)
    setValue(input)
    return
  }
  if (!input.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
    const errorMessage = "正しい電話番号を入力してください"
    ers.phoneNumber = errorMessage
    setError(ers)
    setValue(input)
    return
  }
  ers.phoneNumber = ""
  setError(ers)
  setValue(input)
}

export const inputMailAddress = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors

  if (!value) {
    const errorMessage = "未入力です"
    ers.mailAddress = errorMessage
    setError(ers)
    setValue(value)
    return
  }
  if (!validator.isEmail(value)) {
    //   /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/
    const errorMessage = "正しいEメールを入力してください"
    ers.mailAddress = errorMessage
    setError(ers)
    setValue(value)
    return
  }
  ers.mailAddress = ""
  setError(ers)
  setValue(value)
}

export const inputBirthday = (value, setValue, errors, setError, options) => {
  let ers = errors
  let errorMessage = ""

  if (value) {
    if (!value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      errorMessage = "正しい生年月日を入力してください"
    }
    const y = value.split("-")[0]
    const m = value.split("-")[1] - 1
    const d = value.split("-")[2]
    var date = new Date(y, m, d)
    if (
      date.getFullYear() != y ||
      date.getMonth() != m ||
      date.getDate() != d
    ) {
      errorMessage = "正しい生年月日を入力してください"
    }
    ers.birthday = errorMessage
    setError(ers)
    setValue(value)
  } else {
    ers.birthday = ""
    setError(ers)
    setValue(value)
  }
}

export const inputZipCode = async (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  let errorMessage
  if (!value) {
    errorMessage = "未入力です"
    ers.zipCode = errorMessage
    setError(ers)
    setValue(value)
    return
  }
  if (!value.match(/^\d{7}$/)) {
    errorMessage = "正しい郵便番号を入力してください"
    ers.zipCode = errorMessage
    setError(ers)
    setValue(value)
  }

  if (value.length === 3 || value.length === 7) {
    const prefectureData = await fetchPostalCodes(value)
    if (
      prefectureData !== undefined
    ) {
      options.setPrefectureId(prefectureData.prefecture_id)
      options.setAddress(prefectureData.town_name + prefectureData.address_name)
    }
  }

  if (!errorMessage) {
    ers.zipCode = ""
    setError(ers)
    setValue(value)
  }
}

export const checkZipCode = (value, setValue, errors, setError, options) => {
  let ers = errors
  let errorMessage
  if (!value) {
    errorMessage = "未入力です"
    ers.zipCode = errorMessage
    setError(ers)
    setValue(value)
    return
  }
  if (!value.match(/^\d{7}$/)) {
    errorMessage = "正しい郵便番号を入力してください"
    ers.zipCode = errorMessage
    setError(ers)
    setValue(value)
    return
  }

  ers.zipCode = ""
  setError(ers)
  setValue(value)
}

export const inputPrefectureId = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  const errorMessage = value > 0 ? "" : "未選択です"
  ers.prefectureId = errorMessage
  setError(ers)
  setValue(value)
}

export const inputAddress = (value, setValue, errors, setError, options) => {
  let ers = errors
  if (!value) {
    ers.address = "未入力です"
    setError(ers)
    setValue(value)
    return
  }
  if (value.length < 5) {
    ers.address = "5文字以上で入力してください"
    setError(ers)
    setValue(value)
    return
  }
  ers.address = ""
  setError(ers)
  setValue(value)
}

export const inputAddressDetail = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  setValue(value)
}

export const inputDeliverClientName = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (options.deliver > 0) {
    if (!value) {
      ers.deliverClientName = "未入力です"
      setError(ers)
      setValue(value)
      return
    }
  }
  ers.deliverClientName = ""
  setError(ers)
  setValue(value)
}

export const inputDeliverPhoneNumber = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  const input = value ? value.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, "") : ""
  if (options.deliver > 0) {
    if (validator.isEmpty(input)) {
      ers.deliverPhoneNumber = "未入力です"
      setError(ers)
      setValue(input)
      return
    }
    if (!input.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
      ers.deliverPhoneNumber = "正しい電話番号を入力してください"
      setError(ers)
      setValue(input)
      return
    }
  }
  ers.deliverPhoneNumber = ""
  setError(ers)
  setValue(input)
}

export const inputDeliverZipCode = async (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  let errorMessage
  if (options.deliver > 0) {
    if (!value) {
      errorMessage = "未入力です"
      ers.deliverZipCode = errorMessage
      setError(ers)
      setValue(value)
      return
    }
    if (!value.match(/^\d{7}$/)) {
      errorMessage = "正しい郵便番号を入力してください"
      ers.deliverZipCode = errorMessage
      setError(ers)
      setValue(value)
    }

    if (value.length === 3 || value.length === 7) {
      const prefectureData = await fetchPostalCodes(value)
      if (
        prefectureData !== undefined
      ) {
        options.setDeliverPrefectureId(prefectureData.prefecture_id)
        options.setDeliverAddress(
          prefectureData.town_name + prefectureData.address_name
        )
      }
    }
  }

  if (!errorMessage) {
    ers.deliverZipCode = ""
    setError(ers)
    setValue(value)
  }
}

export const checkDeliverZipCode = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  let errorMessage
  if (options.deliver > 0) {
    if (!value) {
      errorMessage = "未入力です"
      ers.deliverZipCode = errorMessage
      setError(ers)
      setValue(value)
      return
    }
    if (!value.match(/^\d{7}$/)) {
      errorMessage = "正しい郵便番号を入力してください"
      ers.deliverZipCode = errorMessage
      setError(ers)
      setValue(value)
      return
    }
  }

  if (!errorMessage) {
    ers.deliverZipCode = ""
    setError(ers)
    setValue(value)
  }
}

export const inputDeliverPrefectureId = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (options.deliver > 0) {
    const errorMessage = value > 0 ? "" : "未選択です"
    ers.deliverPrefectureId = errorMessage
    setError(ers)
    setValue(value)
  } else {
    ers.deliverPrefectureId = ""
    setError(ers)
    setValue(value)
  }
}

export const inputDeliverAddress = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (options.deliver > 0) {
    if (!value) {
      ers.deliverAddress = "未入力です"
      setError(ers)
      setValue(value)
      return
    }
    if (value.length < 5) {
      ers.deliverAddress = "5文字以上で入力してください"
      setError(ers)
      setValue(value)
      return
    }
  }
  ers.deliverAddress = ""
  setError(ers)
  setValue(value)
}

export const inputDeliverAddressDetail = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  if (options.deliver > 0) {
    setValue(value)
  } else {
    setValue("")
  }
}

export const inputPaymentId = (value, setValue, errors, setError, options) => {
  let ers = errors
  const errorMessage = value > 0 ? "" : "未選択です"
  ers.paymentId = errorMessage
  setError(ers)
  setValue(value)
}

export const inputDeliverId = (value, setValue, errors, setError, options) => {
  let ers = errors
  const errorMessage = value > 0 ? "" : "未選択です"
  ers.deliverId = errorMessage
  setError(ers)
  setValue(value)
}

// 未使用
export const inputDeliverClientDate = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  let errorMessage = ""

  if (value) {
    value = moment(value).format("YYYY-MM-DD")
    if (!value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      errorMessage = "正しい配送希望日を入力してください"
    }
    const y = value.split("-")[0]
    const m = value.split("-")[1] - 1
    const d = value.split("-")[2]

    const date = new Date(y, m, d)
    if (
      date.getFullYear() !== y ||
      date.getMonth() !== m ||
      date.getDate() !== d
    ) {
      errorMessage = "正しい配送希望日を入力してください"
    }
    ers.deliverClientDate = errorMessage
    setError(ers)
    setValue(value)
  } else {
    ers.deliverClientDate = ""
    setError(ers)
    setValue(value)
  }
}

export const inputPassword = (value, setValue, errors, setError, options) => {
  let ers = errors
  if (!value) {
    ers.password = ""
    setError(ers)
    setValue(value)
    return
  } else {
    if (
      !value.match(/^[a-zA-Z0-9]+$/) ||
      value.length < 6 ||
      value.length > 20
    ) {
      ers.password = "正しいパスワードを入力してください"
      setError(ers)
      setValue(value)
      return
    } else {
      ers.password = ""
      setError(ers)
      setValue(value)
      return
    }
  }
}

export const inputComment = (value, setValue, errors, setError, options) => {
  setValue(value)
}

//  credit card
export const inputCardNo = (value, setValue, errors, setError, options) => {
  let ers = errors

  const input = value ? value.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, "") : ""

  if (options.paymentId === "6") {
    if (validator.isEmpty(input)) {
      ers.cardNo = "未入力です"
      setError(ers)
      setValue(input)
      return
    }

    if (!validator.isCreditCard(input)) {
      ers.cardNo = "正しいカード番号を入力してください"
      setError(ers)
      setValue(input)
      return
    }
  }
  ers.cardNo = ""
  setError(ers)
  setValue(input)
}

export const inputCardName = (value, setValue, errors, setError, options) => {
  let ers = errors

  if (options.paymentId === "6") {
    if (validator.isEmpty(value)) {
      ers.cardName = "未入力です"
      setError(ers)
      setValue(value)
      return
    }

    if (!value.match(/^[a-zA-Z]+\s[a-zA-Z]+$/)) {
      ers.cardName = "正しいカード名義を入力してください"
      setError(ers)
      setValue(value)
      return
    }
  }
  ers.cardName = ""
  setError(ers)
  setValue(value)
}

export const inputExpireDate = (value, setValue, errors, setError, options) => {
  let ers = errors
  console.log({ value })
  if (options.paymentId === "6") {
    if (!value) {
      ers.expireDate = "未入力です"
      setError(ers)
      setValue(value)
      return
    }

    if (value.length !== 4) {
      ers.expireDate = "正しい有効期限を入力してください"
      setError(ers)
      setValue(value)
      return
    }
    const im = Number(value.substring(0, 2))
    const expireMonth = Number(value.substring(2, 4) + value.substring(0, 2))

    const d = new Date()
    const nowM =
      String(d.getFullYear()).substring(2, 4) +
      String(d.getMonth() + 1).padStart(2, "0")
    const futureM =
      String(d.getFullYear() + 10).substring(2, 4) +
      String(d.getMonth() + 1).padStart(2, "0")

    const nowMonth = Number(nowM)
    const futureMonth = Number(futureM)

    if (
      expireMonth < nowMonth ||
      expireMonth > futureMonth ||
      im < 1 ||
      im > 12
    ) {
      ers.expireDate = "正しい有効期限を入力してください"
      setError(ers)
      setValue(value)
      return
    }
  }
  ers.expireDate = ""
  setError(ers)
  setValue(value)
}

export const inputSecurityCode = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (options.paymentId === "6") {
    if (!value) {
      ers.securityCode = "未入力です"
      setError(ers)
      setValue(value)
      return
    }

    if (!value.match(/^\d{3,4}$/)) {
      ers.securityCode = "正しいセキュリティコードを入力してください"
      setError(ers)
      setValue(value)
      return
    }
  }
  ers.securityCode = ""
  setError(ers)
  setValue(value)
}

export const inputCreditToken = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (options.paymentId === "6") {
    if (!value) {
      ers.creditToken = "クレジットトークンがありません"
      setError(ers)
      setValue("")
      return
    }
  }
  ers.creditToken = ""
  setError(ers)
  setValue(value)
}

export const inputOrderReferenceId = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (options.paymentId === "7") {
    if (!value) {
      ers.orderReferenceId = "アマゾン・ペイでカードが指定されていません"
      setError(ers)
      setValue("")
      return
    }
  }
  ers.orderReferenceId = ""
  setError(ers)
  setValue(value)
}

// phoneNumber for login
export const inputPhoneNumberLogin = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  const input = value ? value.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, "") : ""

  if (validator.isEmpty(input)) {
    ers.phoneNumberLogin = "未入力です"
    setError(ers)
    setValue(input)
    return
  }
  if (!input.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
    ers.phoneNumberLogin = "正しい電話番号を入力してください"
    setError(ers)
    setValue(input)
    return
  }
  ers.phoneNumberLogin = ""
  setError(ers)
  setValue(input)
}

// password for login
export const inputPasswordLogin = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  if (!value) {
    ers.passwordLogin = "未入力です"
    setError(ers)
    setValue(value)
    return
  } else {
    if (
      !value.match(/^[a-zA-Z0-9]+$/) ||
      value.length < 6 ||
      value.length > 20
    ) {
      ers.passwordLogin = "正しいパスワードを入力してください"
      setError(ers)
      setValue(value)
      return
    } else {
      ers.passwordLogin = ""
      setError(ers)
      setValue(value)
      return
    }
  }
}

// userName for inquiry
export const inputUserNameInquiry = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  const errorMessage = !value ? "未入力です" : ""
  ers.userName = errorMessage
  setError(ers)
  setValue(value)
}

// mailAddress for inquiry
export const inputMailAddressInquiry = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors

  if (!value) {
    const errorMessage = "未入力です"
    ers.mailAddress = errorMessage
    setError(ers)
    setValue(value)
    return
  }
  if (!validator.isEmail(value)) {
    //   /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/
    const errorMessage = "正しいEメールを入力してください"
    ers.mailAddress = errorMessage
    setError(ers)
    setValue(value)
    return
  }
  ers.mailAddress = ""
  setError(ers)
  setValue(value)
}

// comment for inquiry
export const inputCommentInquiry = (
  value,
  setValue,
  errors,
  setError,
  options
) => {
  let ers = errors
  const errorMessage = !value ? "未入力です" : ""
  ers.comment = errorMessage
  setError(ers)
  setValue(value)
}
