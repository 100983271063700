import axios from "axios"

// common //
export const fetchData = async (file, params) => {
  const res = await axios.get(`${process.env.API_URL}/${file}`, {
    params: params,
  })
  return res.data
}

export const fetchDataPost = async (file, params) => {
  try {
    const response = await axios.post(
      `${process.env.API_URL}/${file}`,
      params,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const fetchPostalCodes = async zipCode => {
  try {
    const response = await axios.get(`${process.env.LAMBDA_API}?z=${zipCode}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    if (response.data.address_name == 'null' || response.data.address_name == null) {
      response.data.address_name = ""
    }
    return response.data
  } catch (err) {
    console.log(err)
  }
}
