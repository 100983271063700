import React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import FormHelperText from "@mui/material/FormHelperText"

const BoxSelect = props => {
  const inputName = props.inputName
  const validate = event => {
    props.select(
      event.target.value,
      props.setValue,
      props.errors,
      props.setErrors,
      props.options
    )
  }

  const label = props.required === true ? props.label + " *" : props.label
  const error = props.errors[inputName] ? true : false

  return (
    <FormControl
      required={props.required}
      error={error}
      fullWidth={props.fullWidth}
      component="fieldset"
      className="mt-2"
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        required={props.required}
        value={props.value}
        label={label}
        onChange={event => validate(event)}
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label.replace("null", "")}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className="text-red-500">
        {props.errors[inputName]}
      </FormHelperText>
    </FormControl>
  )
}

export default BoxSelect
