import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import CartPc from "./CartPc"

const MainPc = props => {
  const { inputGoodsId, Scroll, company } = props

  const data = useStaticQuery(graphql`{
  header_bg: file(relativePath: {eq: "pc/f_header02.png"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(width: 960, layout: FIXED)
    }
  }
  main_bg: file(relativePath: {eq: "pc/f_main.png"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(width: 960, layout: FIXED)
    }
  }
}
`)
  return (
    <div className="mx-auto my-0 text-center w-full">
      <header>
        <div className="inner">
          <h1>
            <StaticImage
              width={960}
              height={78}
              src={"../assets/images/pc/f_header01.png"}
              alt={"全ての女性を虜にしてしまう最強のフェロモン香水のFforMEN"}
              placeholder="blurred"
              quality="65"
            />
          </h1>
        </div>
        <div className="headerLine"></div>
      </header>

      <div className="mainView"></div>
      <div className="survey"></div>
      <div className="inner">
        <StaticImage
          width={960}
          height={352}
          src={"../assets/images/pc/f_mainA01.png"}
          alt={"ご注意下さい"}
          placeholder="blurred"
          quality="65"
        />
      </div>

      <CartPc inputGoodsId={inputGoodsId} Scroll={Scroll} flg={1} company={company} />

      <div className="bkbg">
        <div className="inner">
          <div className="img_box">
            <StaticImage
              width={960}
              height={240}
              src={"../assets/images/pc/f_mainA03.png"}
              alt={"お電話でのご注文・お問い合わせはこちら"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={813}
              src={"../assets/images/pc/f_mainA04.png"}
              alt={"恋心を呼び起こす"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={844}
              src={"../assets/images/pc/f_mainA05.png"}
              alt={"女性に香りで恋をする脳はを直接刺激するから"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={521}
              src={"../assets/images/pc/f_mainA06.png"}
              alt={"立場・場所等一切関係なく"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={640}
              src={"../assets/images/pc/f_mainA07.png"}
              alt={"止まらない感情に激しく溺れてしまう"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={958}
              src={"../assets/images/pc/f_mainA08.png"}
              alt={"恋に落ちた女性が続出中!!"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={670}
              src={"../assets/images/pc/f_mainA09.png"}
              alt={"多くの女性が匂いに惹かれていました"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={1315}
              src={"../assets/images/pc/f_mainA10.png"}
              alt={"不細工な俺が・・・"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={1065}
              src={"../assets/images/pc/f_mainA11.png"}
              alt={"40歳を過ぎてモテ期到来"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={126}
              src={"../assets/images/pc/f_mainB01.png"}
              alt={"ここまで女を狂わせるその秘密とは"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={816}
              src={"../assets/images/pc/f_mainB02.png"}
              alt={"直感的に女性の本能を刺激する香り"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={447}
              src={"../assets/images/pc/f_mainB03.png"}
              alt={"では、女性を魅了する匂いとは？"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={835}
              src={"../assets/images/pc/f_mainB04.png"}
              alt={"ずばり、フェロモン"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={960}
              src={"../assets/images/pc/f_mainB04-2.jpg"}
              alt={"オスモフェロモン"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={519}
              src={"../assets/images/pc/f_mainB05.png"}
              alt={"女性を虜に"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={800}
              src={"../assets/images/pc/f_mainB06.png"}
              alt={"女性が求める理想の男性像を演出"}
              placeholder="blurred"
              quality="65"
            />
          </div>

          <CartPc inputGoodsId={inputGoodsId} Scroll={Scroll} flg={1} company={company} />

          <div className="img_box">
            <StaticImage
              width={960}
              height={524}
              src={"../assets/images/pc/f_mainB08.png"}
              alt={"フェロモン香水F for menの上手な付け方"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={406}
              src={"../assets/images/pc/f_mainB09.png"}
              alt={"F for menは直接地肌に付ける"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="img_box">
            <StaticImage
              width={960}
              height={438}
              src={"../assets/images/pc/f_mainB10.png"}
              alt={"F for menを付ける場所"}
              placeholder="blurred"
              quality="65"
            />
          </div>
          <div className="bottom_tel">
            <StaticImage
              width={960}
              height={240}
              src={"../assets/images/pc/f_mainA03.png"}
              alt={"お電話でのご注文・お問い合わせはこちら"}
              placeholder="blurred"
              quality="65"
            />
          </div>
        </div>
      </div>

      <style jsx="true" global="true">
        {`
          .img_box > div {
            overflow: hidden;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            width: 100%;
            margin: 0 auto -1px;
            padding: 0;
          }
          .img_box > div > img {
            width: 100%;
            max-width: 100%;
          }
          ul,
          ol {
            list-style-type: none;
          }

          table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          a img {
            border: none;
          }

          img {
            vertical-align: bottom;
            max-width: 100%;
          }

          mark {
            background: none;
          }

          a,
          a:hover {
            text-decoration: none;
            color: #434343;
          }

          .clearfix:after {
            content: " ";
            display: block;
            visibility: hidden;
            clear: both;
            height: 0.1px;
            font-size: 0.1em;
            line-height: 0;
          }

          .clearfix {
            min-height: 1px;
            zoom: 1;
          }

          div#wrapper {
            margin: 0 auto;
            font-size: 14px;
          }

          .inner {
            width: 950px;
            margin: 0 auto;
          }

          header,
          .bkbg,
          .bkbg02 {
            background: #000000;
          }

          .headerLine {
            background: url(${data.header_bg.publicURL}) center center no-repeat;
            height: 3px;
          }

          .mainView {
            background: url(${data.main_bg.publicURL}) center center no-repeat;
            height: 682px;
          }

          .survey {
            margin-top: -40px;
            position: relative;
            bottom: 0;
            width: 100%;
            height: 40px;
            text-align: center;
            color: #fff;
          }
          .bkbg .inner,
          .bkbg02 .inner,
          .cv {
            position: relative;
          }

          .cv01,
          .cv02 {
            width: 980px;
            position: absolute;
            top: 520px;
            left: 0px;
          }

          .cv01 li,
          .cv02 li {
            float: left;
            margin-right: 10px;
          }

          .cv01 li:last-child,
          .cv02 li:last-child {
            margin-right: 0px;
          }

          .cv02 {
            top: 858px;
          }
          .bottom_tel {
            margin: 40px 0;
          }
        `}
      </style>
    </div>
  )
}
export default MainPc
