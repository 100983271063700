import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import InquiryForm from "./InquiryForm"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"

const Inquiry = props => {
  return (
    <Box>
      <Grid container spacing={2} className="my-3">
        <Grid item xs={12} className="text-center">
          <button
            type="button"
            className="bg-yellow-500 hover:bg-yellow-700 text-white py-3 px-4 rounded inline-flex items-center"
            onClick={props.handleOpen}
          >
            <ForwardToInboxIcon />
            <span className="text-xl pl-2">お問い合わせ</span>
          </button>
        </Grid>
      </Grid>

      <InquiryForm
        open={props.open}
        handleClose={props.handleClose}
        clientId={props.clientId}
        userName={`${props.lastName}${props.firstName}`}
        mailAddress={props.mailAddress}
      />
    </Box>
  )
}

export default Inquiry
