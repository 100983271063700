import React from "react"
import ReactLoading from "react-loading"

const Loading = () => (
  <div className="items-center justify-center flex flex-column flex-wrap">
    <ReactLoading type="spin" color="#000" />
  </div>
)

export default Loading
