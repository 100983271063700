import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import AddressForm from "./AddressForm"

const Address = props => {
  return (
    <Box>
      <Grid container spacing={2} className="my-3">
        <Grid item xs={12} className="p-0 m-0">
          <button
            type="button"
            onClick={props.handleOpen}
            className="rounded-lg px-4 py-2 bg-yellow-500 hover:bg-yellow-600 duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 pr-0 inline-block"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
              <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
            </svg>
            <span className="text-xs pl-2">配送先の編集</span>
          </button>
        </Grid>
      </Grid>

      <AddressForm
        open={props.open}
        handleOpen={props.handleOpen}
        handleClose={props.handleClose}
        clientId={props.clientId}
        clientAddressList={props.clientAddressList}
        prefectureList={props.prefectureList}
        setClientAddressList={props.setClientAddressList}
      />
    </Box>
  )
}

export default Address
