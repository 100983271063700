import React, { useState } from "react"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import FormHelperText from "@mui/material/FormHelperText"

const SelectBirthday = props => {
  const inputName = props.inputName

  const [age, setAge] = useState("")

  const inputBirthYear = e => {
    props.setBirthYear(e.target.value)
    const birthday =
      !e.target.value && !props.birthMonth && !props.birthDay
        ? ""
        : `${e.target.value}-${props.birthMonth}-${props.birthDay}`
    setAge(birthday)
    props.onChange(
      birthday,
      props.setValue,
      props.errors,
      props.setErrors,
      props.options
    )
  }
  const inputBirthMonth = e => {
    props.setBirthMonth(e.target.value)
    const birthday =
      !props.birthYear && !e.target.value && !props.birthDay
        ? ""
        : `${props.birthYear}-${e.target.value}-${props.birthDay}`
    setAge(birthday)
    props.onChange(
      birthday,
      props.setValue,
      props.errors,
      props.setErrors,
      props.options
    )
  }
  const inputBirthDay = e => {
    props.setBirthDay(e.target.value)
    const birthday =
      !props.birthYear && !props.birthMonth && !e.target.value
        ? ""
        : `${props.birthYear}-${props.birthMonth}-${e.target.value}`
    setAge(birthday)
    props.onChange(
      birthday,
      props.setValue,
      props.errors,
      props.setErrors,
      props.options
    )
  }
  // 生年月日入力欄生成
  const setYear = () => {
    const minYear = new Date().getFullYear() - 70
    const maxYear = new Date().getFullYear() - 18

    let list = []
    list.push(
      <option key="0" value="">
        年
      </option>
    )
    for (let i = maxYear; i >= minYear; i--) {
      list.push(
        <option key={`year_${i}`} value={i}>
          {i}
        </option>
      )
    }
    return list
  }

  const setMonth = () => {
    let list = []
    list.push(
      <option key="0" value="">
        月
      </option>
    )
    for (let i = 1; i <= 12; i++) {
      list.push(
        <option key={`month_${i}`} value={i.toString().padStart(2, "0")}>
          {i}
        </option>
      )
    }
    return list
  }

  const setDay = () => {
    let list = []
    list.push(
      <option key="0" value="">
        日
      </option>
    )
    const lastday = new Date(
      Number(props.birthYear),
      Number(props.birthMonth),
      0
    ).getDate()
    for (let i = 1; i <= lastday; i++) {
      list.push(
        <option key={`day_${i}`} value={i.toString().padStart(2, "0")}>
          {i}
        </option>
      )
    }
    return list
  }

  const error = props.errors[inputName] ? true : false

  return (
    <>
      <FormControl
        sx={{ m: 1 }}
        required={props.required}
        fullWidth={props.fullWidth}
        error={error}
        component="fieldset"
        className="mt-2"
      >
        <Select
          native
          value={props.birthYear}
          onChange={inputBirthYear}
          id="birth-year"
          name="birthYear"
        >
          {setYear()}
        </Select>
      </FormControl>

      <FormControl
        sx={{ m: 1 }}
        required={props.required}
        fullWidth={props.fullWidth}
        error={error}
        component="fieldset"
        className="mt-2"
      >
        <Select
          native
          value={props.birthMonth}
          onChange={inputBirthMonth}
          id="birth-month"
          name="birthMonth"
        >
          {setMonth()}
        </Select>
      </FormControl>

      <FormControl
        sx={{ m: 1 }}
        required={props.required}
        fullWidth={props.fullWidth}
        error={error}
        component="fieldset"
        className="mt-2"
      >
        <Select
          native
          value={props.birthDay}
          onChange={inputBirthDay}
          id="birth-day"
          name="birthDay"
        >
          {setDay()}
        </Select>
      </FormControl>
      <FormHelperText className="text-red-600">
        {props.errors[inputName]}
      </FormHelperText>
    </>
  )
}

export default SelectBirthday
