import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import LoginForm from "./LoginForm"
import DividerTitle from "./form_elements/DividerTitle"

const Login = props => {
  return (
    <Box sx={{ marginBottom: "20px" }}>
      {props.isLogin === false && (
        <>
          <DividerTitle title="以前購入された方" />

          <Grid container spacing={2} className="my-3">
            <Grid item xs={12} className="text-center">
              <button
                className="bg-green-500 hover:bg-green-700 text-white py-3 px-4 rounded inline-flex items-center"
                onClick={props.handleOpen}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
                <span className="text-xl pl-2">ログイン</span>
              </button>
            </Grid>
          </Grid>
        </>
      )}

      <LoginForm
        open={props.open}
        isLoading={props.isLoading}
        handleClose={props.handleClose}
        handleSubmit={props.handleSubmitLogin}
        phoneNumber={props.phoneNumberLogin}
        password={props.passwordLogin}
        setPhoneNumber={props.setPhoneNumberLogin}
        setPassword={props.setPasswordLogin}
        errors={props.errorsLogin}
        setErrors={props.setErrorsLogin}
      />
    </Box>
  )
}

export default Login
