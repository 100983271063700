import React, { useEffect, useState } from "react"
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
import emailjs from '@emailjs/browser';
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import {
  inputUserNameInquiry,
  inputMailAddressInquiry,
  inputCommentInquiry,
} from "../libs/validations"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { InputText, Loading } from "./form_elements/index"

const theme = createTheme()

const InquiryForm = props => {
  const [userName, setUserName] = useState("")
  const [mailAddress, setMailAddress] = useState("")
  const [comment, setComment] = useState("")
  const [clientId, setClientId] = useState("")
  const [errors, setErrors] = useState({
    userName: "",
    mailAddress: "",
    comment: "",
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setUserName(props.userName)
    setMailAddress(props.mailAddress)
    setClientId(props.clientId)
  }, [props.userName, props.mailAddress])

  const handleSubmit = async event => {
    event.preventDefault()

    inputUserNameInquiry(userName, setUserName, errors, setErrors, {})
    inputMailAddressInquiry(mailAddress, setMailAddress, errors, setErrors, {})
    inputCommentInquiry(comment, setComment, errors, setErrors, {})

    if (
      errors.userName === "" &&
      errors.mailAddress === "" &&
      errors.comment === ""
    ) {
      setTimeout(() => {
        setIsLoading(true)

        const params ={
          user_name: userName,
          email: mailAddress,
          message: comment
        }

        emailjs.send(
          process.env.EMAILJS_SERVICE_ID,
          process.env.EMAILJS_TEMPLATE_ID,
          params,
          process.env.EMAILJS_PUBKEY
        );

        /*
        const sqs = new SQSClient({
          region: process.env.AWS_REGION,
          credentials: {
            accessKeyId: process.env.AWS_ACCESS_KEY,
            secretAccessKey: process.env.AWS_SECRET_KEY,
          }
        });

        const mailInfo = {
          subject: `${process.env.SITE_NAME}からお問い合わせがありました`,
          from: `${userName} <${mailAddress}>`,
          to: process.env.ADMIN_MAIL,
          content: comment
        }

        var params = {
          MessageBody: JSON.stringify(mailInfo),
          MessageGroupId: process.env.PREFIX,
          QueueUrl: process.env.SQS_SENDGRID_QUEUE_URL
        };
        console.log({params})
        const command = new SendMessageCommand(params);
        sqs.send(command);
        */

        setIsLoading(false)
        window.alert("お問い合わせを送信致しました。")
        setComment("")
        handleClose()
      }, 1000)
    } else {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setErrors({
      userName: "",
      mailAddress: "",
      comment: "",
    })
    props.handleClose()
  }

  return (
    // props received from App.js
    <Dialog open={props.open} onClose={props.handleClose}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              className="text-center bg-gray-100 p-2 border-2"
              sx={{ mb: 3 }}
            >
              お問い合わせ
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              ※全て入力してください
              <InputText
                fullWidth={true}
                label={"お名前"}
                multiline={false}
                required={true}
                onChange={inputUserNameInquiry}
                rows={1}
                type={"text"}
                inputName="userName"
                value={userName}
                setValue={setUserName}
                errors={errors}
                setErrors={setErrors}
              />
              <InputText
                fullWidth={true}
                label={"Eメール"}
                multiline={false}
                required={true}
                onChange={inputMailAddressInquiry}
                rows={1}
                inputName="mailAddress"
                value={mailAddress}
                type={"email"}
                setValue={setMailAddress}
                errors={errors}
                setErrors={setErrors}
              />
              <InputText
                fullWidth={true}
                label={"お問い合わせ内容"}
                multiline={true}
                required={true}
                onChange={inputCommentInquiry}
                rows={5}
                inputName="comment"
                value={comment}
                type={"text"}
                setValue={setComment}
                errors={errors}
                setErrors={setErrors}
              />
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    sx={{ mt: 1, mb: 2, p: 1 }}
                  >
                    キャンセル
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  {isLoading === false ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1, mb: 2, p: 1 }}
                      disabled={
                        userName === "" ||
                        mailAddress === "" ||
                        comment === "" ||
                        errors.userName !== "" ||
                        errors.mailAddress !== "" ||
                        errors.comment !== ""
                          ? true
                          : false
                      }
                    >
                      送信
                    </Button>
                  ) : (
                    <Loading />
                  )}
                  {errors.notFound && (
                    <div className="text-red-500 font-bold text-sm text-center mb-5">
                      {errors.notFound}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Dialog>
  )
}

export default InquiryForm
