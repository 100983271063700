import React, { useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { InputText, BoxSelect, Loading } from "./form_elements/index"
import { fetchDataPost } from "../libs/fetchers"
import {
  inputDeliverClientName,
  inputDeliverPhoneNumber,
  inputDeliverZipCode,
  checkDeliverZipCode,
  inputDeliverPrefectureId,
  inputDeliverAddress,
  inputDeliverAddressDetail,
} from "../libs/validations"

import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import AppRegistrationIcon from "@mui/icons-material/AppRegistration"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme()

const AddressForm = ({
  open,
  handleClose,
  clientId,
  clientAddressList,
  prefectureList,
  setClientAddressList,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [clientAddressId, setClientAddressId] = useState(0)
  const [deliverClientName, setDeliverClientName] = useState("")
  const [deliverZipCode, setDeliverZipCode] = useState("")
  const [deliverPrefectureId, setDeliverPrefectureId] = useState("")
  const [deliverAddress, setDeliverAddress] = useState("")
  const [deliverAddressDetail, setDeliverAddressDetail] = useState("")
  const [deliverPhoneNumber, setDeliverPhoneNumber] = useState("")
  const [errors, setErrors] = useState({
    deliverClientName: "",
    deliverZipCode: "",
    deliverPrefectureId: "",
    deliverAddress: "",
    deliverAddressDetail: "",
    deliverPhoneNumber: "",
  })

  const windowClose = () => {
    setErrors({
      deliverClientName: "",
      deliverZipCode: "",
      deliverPrefectureId: "",
      deliverAddress: "",
      deliverAddressDetail: "",
      deliverPhoneNumber: "",
    })
    setDeliverClientName("")
    setDeliverZipCode("")
    setDeliverPrefectureId("")
    setDeliverAddress("")
    setDeliverAddressDetail("")
    setDeliverPhoneNumber("")
    setClientAddressId(0)
    setIsLoading(false)
    handleClose()
  }

  const handleRegist = () => {
    setDeliverClientName("")
    setDeliverZipCode("")
    setDeliverPrefectureId("")
    setDeliverAddress("")
    setDeliverAddressDetail("")
    setDeliverPhoneNumber("")
    setClientAddressId(0)
  }

  const handleEdit = async clientAddressId => {
    setClientAddressId(clientAddressId)
    let params = new URLSearchParams()

    params.append("clientAddressId", clientAddressId)
    params.append("API_KEY", process.env.API_KEY)

    const data = await fetchDataPost("client_address/getAddress.php", params)
    setDeliverClientName(data.deliver_client_name)
    setDeliverZipCode(data.deliver_zip_code)
    setDeliverPrefectureId(data.deliver_prefecture_id)
    setDeliverAddress(data.deliver_address)
    setDeliverAddressDetail(data.deliver_address_detail)
    setDeliverPhoneNumber(data.deliver_phone_number)
    setClientAddressId(data.client_address_id)
  }

  // this form validation
  const handleSubmit = async event => {
    event.preventDefault()
    let params = new URLSearchParams()

    inputDeliverClientName(
      deliverClientName,
      setDeliverClientName,
      errors,
      setErrors,
      { deliver: 1 }
    )

    inputDeliverPhoneNumber(
      deliverPhoneNumber,
      setDeliverPhoneNumber,
      errors,
      setErrors,
      { deliver: 1 }
    )
    checkDeliverZipCode(deliverZipCode, setDeliverZipCode, errors, setErrors, {
      deliver: 1,
    })
    inputDeliverPrefectureId(
      deliverPrefectureId,
      setDeliverPrefectureId,
      errors,
      setErrors,
      { deliver: 1 }
    )
    inputDeliverAddress(deliverAddress, setDeliverAddress, errors, setErrors, {
      deliver: 1,
    })

    if (
      errors.deliverClientName === "" &&
      errors.deliverZipCode === "" &&
      errors.deliverPrefectureId === "" &&
      errors.deliverAddress === "" &&
      errors.deliverPhoneNumber === ""
    ) {
      params.append("clientId", clientId)
      params.append("clientAddressId", clientAddressId)
      params.append("deliverClientName", deliverClientName)
      params.append("deliverZipCode", deliverZipCode)
      params.append("deliverPrefectureId", deliverPrefectureId)
      params.append("deliverAddress", deliverAddress)
      params.append("deliverAddressDetail", deliverAddressDetail)
      params.append("deliverPhoneNumber", deliverPhoneNumber)
      params.append("API_KEY", process.env.API_KEY)

      setIsLoading(true)

      const data = await fetchDataPost(
        "client_address/createAddress.php",
        params
      )
      setTimeout(() => {
        if (data.length > 0) {
          const newAddress = data.map(e => ({
            value: e["client_address_id"],
            label: `${e["deliver_client_name"]}（${
              e["deliver_prefecture_name"]
            }${e["deliver_address"]} ${e[
              "deliver_address_detail"
            ].toString()}）`,
          }))
          setClientAddressList(newAddress)
          setDeliverClientName("")
          setDeliverZipCode("")
          setDeliverPrefectureId("")
          setDeliverAddress("")
          setDeliverAddressDetail("")
          setDeliverPhoneNumber("")
          setClientAddressId(0)
        }
        setIsLoading(false)
      }, 2000)
    } else {
      setIsLoading(false)
      console.log("address error", errors)
    }
  }

  // this form validation
  const deleteAddress = async caid => {
    const confirmed = window.confirm("1件の住所を削除します。よろしいですか？")
    if (confirmed) {
      let params = new URLSearchParams()

      params.append("clientId", clientId)
      params.append("clientAddressId", caid)
      params.append("API_KEY", process.env.API_KEY)

      setIsLoading(true)
      const data = await fetchDataPost(
        "client_address/deleteAddress.php",
        params
      )

      if (data.length > 0) {
        const newAddress = data.map(e => ({
          value: e["client_address_id"],
          label: `${e["deliver_client_name"]}（${e["deliver_prefecture_name"]}${
            e["deliver_address"]
          } ${e["deliver_address_detail"].toString()}）`,
        }))
        setClientAddressList(newAddress)
      }

      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    }
  }

  return (
    // props received from App.js
    <Dialog open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />

          <Typography
            component="h1"
            variant="h5"
            className="text-center bg-gray-100 p-1 border-2"
            sx={{ mt: 3 }}
          >
            配送先の編集
          </Typography>

          <Box
            component="form"
            // onSubmit={handleSubmitAddressList}
            noValidate
            sx={{ mt: 1 }}
          >
            <Divider className="text-lg font-bold mt-3 w-full">
              登録済みの配送先一覧
            </Divider>
            <p className="text-sm text-right my-2">※最大5つまで登録できます</p>
            {isLoading === false ? (
              <Grid container>
                {clientAddressList.map((address, index) => {
                  return (
                    <Grid item xs={12}>
                      <ListItem
                        key={index}
                        disableGutters
                        secondaryAction={
                          clientAddressList.length > 1 && (
                            <IconButton>
                              <DeleteForeverIcon
                                onClick={() => deleteAddress(address.value)}
                              />
                            </IconButton>
                          )
                        }
                      >
                        <ListItemIcon sx={{ cursor: "pointer" }}>
                          <EditIcon onClick={() => handleEdit(address.value)} />
                        </ListItemIcon>
                        <ListItemText
                          primary={address.label}
                          sx={{
                            cursor: "pointer",
                          }}
                          style={{ textDecoration: "underline" }}
                          onClick={() => handleEdit(address.value)}
                        />
                      </ListItem>
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <Loading />
            )}
            <div className="text-right">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => windowClose()}
              >
                閉じる
              </Button>
            </div>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Divider className="text-lg font-bold w-full">
              {clientAddressId > 0
                ? "「" + deliverClientName + "」の編集"
                : "配送先の新規登録"}
              {clientAddressId > 0 && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => handleRegist()}
                >
                  新規登録
                </Button>
              )}
            </Divider>
            <Grid container spacing={2} className="mb-5">
              <Grid item xs={12}>
                <InputText
                  fullWidth={true}
                  label={"配送先名"}
                  multiline={false}
                  required={true}
                  onChange={inputDeliverClientName}
                  rows={1}
                  inputName="deliverClientName"
                  value={deliverClientName}
                  type={"text"}
                  setValue={setDeliverClientName}
                  errors={errors}
                  setErrors={setErrors}
                  options={{ deliver: 1 }}
                />
              </Grid>
            </Grid>

            {/* deliver_phone_number */}
            <Grid container spacing={2} className="mb-5">
              <Grid item xs={12}>
                <InputText
                  fullWidth={true}
                  label={"配送先電話番号"}
                  multiline={false}
                  required={true}
                  onChange={inputDeliverPhoneNumber}
                  rows={1}
                  inputName="deliverPhoneNumber"
                  value={deliverPhoneNumber}
                  type={"tel"}
                  maxLength={11}
                  helperText="例：09012345678"
                  setValue={setDeliverPhoneNumber}
                  errors={errors}
                  setErrors={setErrors}
                  options={{ deliver: 1 }}
                />
              </Grid>
            </Grid>

            {/*配送先住所 */}
            <Grid container spacing={2} className="mb-5">
              <Grid item xs={12} md={6}>
                <InputText
                  fullWidth={true}
                  label={"郵便番号"}
                  multiline={false}
                  required={true}
                  onChange={inputDeliverZipCode}
                  rows={1}
                  inputName="deliverZipCode"
                  value={deliverZipCode}
                  type={"tel"}
                  maxLength={7}
                  helperText="例：1600023"
                  setValue={setDeliverZipCode}
                  errors={errors}
                  setErrors={setErrors}
                  options={{
                    deliver: 1,
                    deliverPrefectureId: deliverPrefectureId,
                    deliverAddress: deliverAddress,
                    setDeliverPrefectureId: setDeliverPrefectureId,
                    setDeliverAddress: setDeliverAddress,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BoxSelect
                  fullWidth={true}
                  label={"都道府県"}
                  required={true}
                  select={inputDeliverPrefectureId}
                  inputName="deliverPrefectureId"
                  value={deliverPrefectureId}
                  options={prefectureList}
                  setValue={setDeliverPrefectureId}
                  errors={errors}
                  setErrors={setErrors}
                />
              </Grid>

              <Grid item xs={12}>
                <InputText
                  fullWidth={true}
                  label={"住所"}
                  multiline={false}
                  required={true}
                  onChange={inputDeliverAddress}
                  rows={2}
                  inputName="deliverAddress"
                  value={deliverAddress}
                  type={"text"}
                  helperText="例：新宿区西新宿７－１－１"
                  setValue={setDeliverAddress}
                  errors={errors}
                  setErrors={setErrors}
                  options={{ deliver: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText
                  fullWidth={true}
                  label={"アパート・マンション"}
                  multiline={false}
                  required={false}
                  onChange={inputDeliverAddressDetail}
                  rows={2}
                  inputName="deliverAddressDetail"
                  value={deliverAddressDetail}
                  type={"text"}
                  helperText="例：西新宿ビル１０１"
                  setValue={setDeliverAddressDetail}
                  errors={errors}
                  setErrors={setErrors}
                  options={{ deliver: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mb-5">
              <Grid item xs={8} className="text-center">
                <LoadingButton
                  onClick={handleSubmit}
                  endIcon={<AppRegistrationIcon />}
                  loading={isLoading}
                  loadingPosition="end"
                  variant="contained"
                  disabled={
                    deliverClientName === "" ||
                    deliverZipCode === "" ||
                    deliverPrefectureId === "" ||
                    errors.deliverAddress !== "" ||
                    errors.deliverPhoneNumber !== "" ||
                    (clientAddressId < 1 && clientAddressList.length > 4)
                      ? true
                      : false
                  }
                >
                  {clientAddressId > 0
                    ? "「" + deliverClientName + "」を更新"
                    : "新規登録"}
                </LoadingButton>
              </Grid>
              <Grid item xs={4} className="text-center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => windowClose()}
                >
                  閉じる
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </Dialog>
  )
}

export default AddressForm
