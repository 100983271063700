import React, {useState} from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Config } from "../../site.config"
import Delivery from "./Delivery"
import { FcUndo, FcInTransit } from "react-icons/fc";

const CartPc = props => {
  const { inputGoodsId, Scroll, flg, company } = props
  const [openDelivery, setOpenDelivery] = useState(false)
  const [deliveryRef, setDeliveryRef] = useState('all')

  const handleOpenDelivery = (deliveryRef) => {
    setDeliveryRef(deliveryRef)
    setOpenDelivery(true)
  }

  const handleCloseDelivery = () => {
    setOpenDelivery(false)
  }

  return (
    <div className="cv mx-auto" style={{ width: "960px" }}>
      <StaticImage
        width={960}
        height={1084}
        src={"../assets/images/pc/f_mainA02n.png"}
        alt={"どんな助成もオトしてしまうフェロモン香水"}
        placeholder="blurred"
        quality="65"
      />
      <div id="offer" className="cv01 grid grid-cols-4 gap-0">
        <div className="buy1">
          <StaticImage
            width={241}
            height={391}
            src={"../assets/images/pc/1_03.png"}
            alt={"F-Formen 1本"}
            placeholder="blurred"
            quality="65"
          />
          <Scroll to="cartForm" smooth={true} duration={600}>
            <StaticImage
              width={220}
              height={60}
              src={"../assets/images/pc/f_mainA02cvbtn.png"}
              alt={"ご注文はコチラ"}
              placeholder="blurred"
              quality="65"
              onClick={() => inputGoodsId(Config.productInfo[0].id)}
              style={{ cursor: "pointer" }}
            />
          </Scroll>
        </div>

        <div className="buy1">
          <StaticImage
            width={241}
            height={391}
            src={"../assets/images/pc/2_03.png"}
            alt={"F-Formen 2本"}
            placeholder="blurred"
            quality="65"
          />
          <Scroll to="cartForm" smooth={true} duration={600}>
            <StaticImage
              width={220}
              height={60}
              src={"../assets/images/pc/f_mainA02cvbtn.png"}
              alt={"ご注文はコチラ"}
              placeholder="blurred"
              quality="65"
              onClick={() => inputGoodsId(Config.productInfo[1].id)}
              style={{ cursor: "pointer" }}
            />
          </Scroll>
        </div>

        <div className="buy1">
          <StaticImage
            width={240}
            height={391}
            src={"../assets/images/pc/3_03.png"}
            alt={"F-Formen 3本"}
            placeholder="blurred"
            quality="65"
          />
          <Scroll to="cartForm" smooth={true} duration={600}>
            <StaticImage
              width={220}
              height={60}
              src={"../assets/images/pc/f_mainA02cvbtn.png"}
              alt={"ご注文はコチラ"}
              placeholder="blurred"
              quality="65"
              onClick={() => inputGoodsId(Config.productInfo[2].id)}
              style={{ cursor: "pointer" }}
            />
          </Scroll>
        </div>

        <div className="buy1">
          <StaticImage
            width={238}
            height={391}
            src={"../assets/images/pc/4_03.png"}
            alt={"F-Formen 5本"}
            placeholder="blurred"
            quality="65"
          />
          <Scroll to="cartForm" smooth={true} duration={600}>
            <StaticImage
              width={220}
              height={60}
              src={"../assets/images/pc/f_mainA02cvbtn.png"}
              alt={"ご注文はコチラ"}
              placeholder="blurred"
              quality="65"
              onClick={() => inputGoodsId(Config.productInfo[3].id)}
              style={{ cursor: "pointer" }}
            />
          </Scroll>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 ml-5 mt-3">
        <div></div>
        <div className="text-center">
          <span className="text-xl inline-block align-top"><FcUndo /></span>
          <span className="text-md font-semibold inline-block cursor-pointer text-white" onClick={() =>handleOpenDelivery('refund')}>&nbsp;返品について</span>
        </div>
        <div className="text-center">
          <span className="text-xl inline-block align-top"><FcInTransit /></span>
          <span className="text-md font-semibold inline-block cursor-pointer text-white" onClick={() =>handleOpenDelivery('delivery')}>&nbsp;配送について</span>
        </div>
        <div></div>
      </div>
      <Delivery
        open={openDelivery}
        handleClose={handleCloseDelivery}
        company={company}
        deliveryRef={deliveryRef}
      />
    </div>
  )
}

export default CartPc
